import React, { useState } from "react";
import {
  ToolBarContainer,
  ToolConfigurationHeader,
  ToolConfigurationBody,
  ToolConfigurationFooter,
  PopoverHdr,
  ToolConfigurationSaveButton,
  ToolConfigurationCancelButton,
  SuggestionCardContainer,
  SuggestionCard,
} from "./style";
import DeleteIcon from "../../assets/workflows/delete-icon.svg";
import Tooltip from "@mui/material/Tooltip";

import {
  TextField,
  TextareaAutosize,
  FormControl,
  Select,
  MenuItem,
  Box,
  InputLabel,
} from "@mui/material";
import SparklesIcon from "../../assets/workflows/sparkles-icon.svg";
import InfoIcon from "../../assets/workflows/info-icon.svg";

const ToolConfiguration = ({
  treeData,
  node,
  selectedAction,
  handleDelete,
  description,
  setDescription,
  descriptionError,
  setDescriptionError,
  configValues,
  setConfigValues,
  configTypes,
  setConfigTypes,
  configErrors,
  setConfigErrors,
  selectedOptions,
  setSelectedOptions,
  onClose,
  onSave,
}) => {
  const [suggestions, setSuggestions] = useState([]);

  const handleConfigChange = (title, value) => {
    setConfigValues((prev) => ({
      ...prev,
      [title]: value,
    }));
    setConfigErrors((prev) => ({
      ...prev,
      [title]: false,
    }));
  };

  const handleOptionChange = (title, value) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [title]: value,
    }));
    setConfigErrors((prev) => ({
      ...prev,
      [title]: false,
    }));
  };

  const handleConfigTypeChange = (title, type, config) => {
    setConfigTypes((prev) => ({
      ...prev,
      [title]: type,
    }));

    if (type === "Use AI") {
      setConfigValues((prev) => ({
        ...prev,
        [title]: "",
      }));
      setConfigErrors((prev) => ({
        ...prev,
        [title]: false,
      }));
    }

    if (type === "Dynamic") {
      setConfigValues((prev) => ({
        ...prev,
        [title]: config.hint,
      }));
      setConfigErrors((prev) => ({
        ...prev,
        [title]: false,
      }));
    }
  };

  const removeNodeById = (currentNode, nodeId) => {
    if (currentNode.id === nodeId) {
      return null;
    }

    if (currentNode.children) {
      currentNode.children = currentNode.children
        .map((child) => removeNodeById(child, nodeId))
        .filter((child) => child !== null);
    }

    return currentNode;
  };

  const handleGetSuggestions = async () => {
    const deepCopyTreeData = JSON.parse(JSON.stringify(treeData));
    const updatedTreeData = deepCopyTreeData
      .map((item) => removeNodeById(item, node.id))
      .filter((item) => item !== null);

    const payload = {
      workflow_history: JSON.stringify(updatedTreeData),
    };
    try {
      const response = await fetch(
        "https://assistant-api.aiflowpro.io/workflows_condition_suggestions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();
      setSuggestions(data);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setDescription(suggestion);
    setSuggestions([]);
  };

  return (
    <ToolBarContainer>
      <ToolConfigurationHeader>
        {node?.img === "ConditionIcon" ? (
          "Condition Info"
        ) : node?.img === "TriggerIcon" ? (
          "Trigger Info"
        ) : node?.img === "EndIcon" ? (
          "End Info"
        ) : (
          <div className="flex flex-col gap-2">
            <div>{selectedAction?.toolName}</div>
            <div style={{ fontSize: "12px", fontWeight: "400" }}>
              {selectedAction?.toolDescription}
            </div>
          </div>
        )}
        {node?.img !== "TriggerIcon" && (
          <Tooltip title="Delete" placement="top">
            <img src={DeleteIcon} alt="Delete" onClick={handleDelete} />
          </Tooltip>
        )}
      </ToolConfigurationHeader>
      <ToolConfigurationBody>
        {(node?.img === "ConditionIcon" || node?.img === "TriggerIcon") && (
          <>
            <PopoverHdr
              style={{
                fontSize: "16px",
                fontWeight: "600",
                marginBottom: "0.4rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  justifyContent: "space-between",
                }}
              >
                {node?.img === "ConditionIcon"
                  ? "Enter a condition with yes or no answers."
                  : "Enter a scenario where you want to trigger this workflow."}
                {node?.img === "ConditionIcon" && (
                  <Tooltip title="Get Suggestions from AI" placement="top">
                    <img
                      style={{ cursor: "pointer" }}
                      src={SparklesIcon}
                      alt="Sparkles"
                      onClick={handleGetSuggestions}
                    />
                  </Tooltip>
                )}
              </div>
            </PopoverHdr>
            {suggestions.length > 0 && (
              <div style={{ marginBottom: "0.5rem" }}>
                <SuggestionCardContainer>
                  {suggestions.map((suggestion) => (
                    <SuggestionCard
                      key={suggestion}
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion}
                    </SuggestionCard>
                  ))}
                </SuggestionCardContainer>
              </div>
            )}

            <TextField
              placeholder={
                node?.img === "ConditionIcon"
                  ? "Are there any items in my todo list?"
                  : "When I inquire about fetching news articles during a conversation"
              }
              fullWidth
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                setDescriptionError(false);
              }}
              error={descriptionError}
              helperText={descriptionError ? "Description is required" : ""}
            />
          </>
        )}
        {selectedAction &&
          node?.img !== "ConditionIcon" &&
          node?.img !== "TriggerIcon" &&
          node?.img !== "EndIcon" && (
            <>
              {JSON.parse(selectedAction.configuration).map((config, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.4rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <PopoverHdr
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      {config.title}
                    </PopoverHdr>
                    {config.type !== "Dropdown" && (
                      <FormControl size="small" sx={{ minWidth: 120 }}>
                        <Select
                          value={configTypes[config.title] || "Static"}
                          onChange={(e) =>
                            handleConfigTypeChange(
                              config.title,
                              e.target.value,
                              config
                            )
                          }
                        >
                          <MenuItem value="Use AI">Use AI</MenuItem>
                          <MenuItem value="Dynamic">Dynamic</MenuItem>
                          <MenuItem value="Static">Static</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  </div>
                  {config.type === "Text Field" ? (
                    <TextField
                      fullWidth
                      placeholder={
                        configTypes[config.title] === "Use AI"
                          ? "Instructions to pick up from flow"
                          : config.hint
                      }
                      value={configValues[config.title] || ""}
                      onChange={(e) =>
                        handleConfigChange(config.title, e.target.value)
                      }
                      error={configErrors[config.title]}
                      helperText={
                        configErrors[config.title]
                          ? "This field is required"
                          : ""
                      }
                      disabled={configTypes[config.title] === "Dynamic"}
                    />
                  ) : config.type === "Text Area" ? (
                    <TextareaAutosize
                      minRows={3}
                      placeholder={
                        configTypes[config.title] === "Use AI"
                          ? "Instructions to pick up from flow"
                          : config.hint
                      }
                      value={configValues[config.title] || ""}
                      onChange={(e) =>
                        handleConfigChange(config.title, e.target.value)
                      }
                      disabled={configTypes[config.title] === "Dynamic"}
                      style={{
                        width: "-webkit-fill-available",
                        padding: "11px 16px",
                        borderRadius: "1rem",
                        border: configErrors[config.title]
                          ? "1px solid #d32f2f"
                          : "1px solid rgba(0, 0, 0, 0.23)",
                        fontFamily: "inherit",
                      }}
                    />
                  ) : (
                    config.type === "Dropdown" && (
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                          <InputLabel id={`select-label-${config.title}`}>
                            {config.title}
                          </InputLabel>
                          <Select
                            labelId={`select-label-${config.title}`}
                            id={`select-${config.title}`}
                            value={selectedOptions[config.title] || ""}
                            label={config.title}
                            onChange={(e) =>
                              handleOptionChange(config.title, e.target.value)
                            }
                            error={configErrors[config.title]}
                          >
                            {config.options.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    )
                  )}
                  {configErrors[config.title] && config.type === "Dropdown" && (
                    <p
                      style={{
                        color: "#d32f2f",
                        fontSize: "0.75rem",
                      }}
                    >
                      This field is required
                    </p>
                  )}
                  <p
                    style={{
                      fontSize: "14px",
                      marginTop: "unset",
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    <img src={InfoIcon} alt="Info" />
                    {config.description}
                  </p>
                </div>
              ))}
            </>
          )}
      </ToolConfigurationBody>
      <ToolConfigurationFooter>
        <ToolConfigurationCancelButton variant="outlined" onClick={onClose}>
          Cancel
        </ToolConfigurationCancelButton>
        <ToolConfigurationSaveButton variant="contained" onClick={onSave}>
          Save
        </ToolConfigurationSaveButton>
      </ToolConfigurationFooter>
    </ToolBarContainer>
  );
};

export default ToolConfiguration;
