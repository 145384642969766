import React, {useState} from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import { Container, Header, Heading, Content } from "../Summarize/Style";
import { Iframe } from "../Iframe/Style";
import BackIcon from "../../assets/back-icon.svg";

export default function Forest() {
  const user_id = localStorage.getItem("id");
  const email = localStorage.getItem("email");
  const isForestOnboarded = localStorage.getItem("isForestOnboarded"); 
  const [iframeSrc, setIframeSrc] = useState(`https://forest.aiflowpro.io/?user_id=${user_id}&email=${email}&isForestOnboarded=${isForestOnboarded}`);
  const { isSidebarOpen } = useOutletContext();
  const navigate = useNavigate();
  return (
    <Container isOpen={isSidebarOpen} style={{ marginRight: "1.4rem" }}>
      <Header style={{ alignItems: "center", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }} onClick={() => navigate("/")}>
          <img src={BackIcon} alt="back" />
          <Heading>Forest App</Heading>
        </div>
      </Header>
      <Content>
        <Iframe src={iframeSrc} />
      </Content>
    </Container>
  );
}