import React, { useEffect, useState, useRef } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import s3 from "../Environment/Asconfig";
import RemovePdfModal from "../modals/RemovePdfModal";
import DriveFilePicker from "./DriveFilePicker";
import {
  Container,
  DragAndDrop,
  HeaderText,
  IconDiv,
  InputContainer,
  LeftPane,
  LeftPaneHeader,
  RightPane,
  SupportText,
  UploadText,
  Input,
  ChatDiv,
  ChatBox,
  StrongText,
  ChatText,
  QuestionDiv,
  PdfContainer,
  Header,
  Heading,
  ContentDiv,
  UserChatDiv,
  UserChatBox,
  ScrollableChat,
  ClearButton,
  LoadingText,
  DriveUploadBtn,
  OrDivider,
} from "./Style";
import PdfViewer from "../../Components/PdfViewer/PdfViewer";
import DrivePdfViewer from "../DrivePdfViewer";
import UploadIcon from "../../assets/upload.svg";
import ArrowUpIcon from "../../assets/arrow-up.svg";
import BlueIcon from "../../assets/blue-icon.svg";
import ChatIcon from "../../assets/chat-icon.svg";
import BackIcon from "../../assets/back-icon.svg";
import Drive from "../../assets/Gdrive.png";
import CrossIcon from "../../assets/cross-in-square.svg";

export default function Summarize() {
  const [file, setFile] = useState(null);
  const [showRemoveFileModal, setShowRemoveFileModal] = useState(false); // State for modal visibility
  const { isSidebarOpen } = useOutletContext();
  const [isDragActive, setIsDragActive] = useState(false);
  const [error, setError] = useState(null);
  const [userMessages, setUserMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [objectKey, setObjectKey] = useState("");
  const [conversation, setConversation] = useState([]);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [driveFiles, setDriveFiles] = useState(null);
  const navigate = useNavigate();
  const scrollableChatRef = useRef(null);

  // const handleDriveFiles = async (links) => {
  //   const fileId = links[0]; // Assuming you are dealing with a single file for simplicity
  //   const apiKey = process.env.REACT_APP_API_KEY; // Replace with your actual API key
  //   const fileUrl = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media&key=${apiKey}`;

  //   try {
  //     const response = await fetch(fileUrl);
  //     if (!response.ok) {
  //       throw new Error(`Error fetching file: ${response.statusText}`);
  //     }
  //     const blob = await response.blob();
  //     const fileURL = URL.createObjectURL(blob);
  //     setFile(fileURL); // Store the object URL to pass it to PDF viewer
  //   } catch (error) {
  //     console.error("Error downloading file:", error);
  //   }
  // };

  const handleDriveFiles = async (links) => {
    const fileId = links[0]; // Assuming you are dealing with a single file for simplicity
    const apiKey = process.env.REACT_APP_API_KEY; // Replace with your actual API key
    const fileUrl = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media&key=${apiKey}`;

    try {
      // Fetch the file from Google Drive
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error(`Error fetching file: ${response.statusText}`);
      }

      // Convert the response to a Blob
      const blob = await response.blob();
      const selectedFile = new File([blob], "downloaded_file.pdf", {
        type: "application/pdf",
      }); // Create a File object

      // Set the loading state
      setLoadingFile(true);
      setFile(selectedFile); // Set the file to state for the PDF viewer

      // Prepare FormData for upload
      const formData = new FormData();
      formData.append("files", selectedFile);

      // Upload the file to the server
      const uploadResponse = await fetch(
        "https://processor.dialect-ai.com/get_pdf_content",
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await uploadResponse.json();
      console.log("Response from server:", data);
      setObjectKey(data.model_path); // Assuming model_path is what you need from the response

      // Create a URL for the downloaded file and set it to state
      const fileURL = URL.createObjectURL(blob);
      setFile(fileURL); // Store the object URL to pass it to PDF viewer

      // Reset state
      setError(null);
      setConversation([]);
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setLoadingFile(false);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === "application/pdf") {
      setLoadingFile(true);
      setFile(selectedFile);
      const formData = new FormData();
      formData.append("files", selectedFile);

      fetch("https://processor.dialect-ai.com/get_pdf_content", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Response from server:", data);
          setObjectKey(data.model_path);
        })
        .catch((error) => {
          console.error("Error uploading PDF:", error);
        })
        .finally(() => {
          setLoadingFile(false);
        });

      setFile(URL.createObjectURL(selectedFile));
      setError(null);
      setConversation([]);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragActive(false);
    const selectedFile = event.dataTransfer.files[0];
    if (selectedFile && selectedFile.type === "application/pdf") {
      setLoadingFile(true);
      const formData = new FormData();
      formData.append("files", selectedFile);

      fetch("https://processor.dialect-ai.com/get_pdf_content", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Response from server:", data);
          setObjectKey(data.model_path);
        })
        .catch((error) => {
          console.error("Error uploading PDF:", error);
        })
        .finally(() => {
          setLoadingFile(false);
        });

      setFile(URL.createObjectURL(selectedFile));
      setError(null);
      setConversation([]);
    } else {
      setError("Please upload a valid PDF file.");
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleSendMessage = async () => {
    if (inputValue.trim() !== "") {
      const userQuery = {
        role: "USER",
        message: inputValue,
      };
      const newMessages = [...conversation, userQuery];
      setConversation(newMessages);
      setInputValue("");
      setLoadingMessage(true);

      const requestBody = {
        user_query: newMessages,
        language: "en",
        object_key: objectKey,
      };

      try {
        const response = await fetch(
          "https://langchain.dialect-ai.com/chat_with_pdf",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to get response from AI");
        }

        const data = await response.json();
        console.log("AI response:", data);

        const aiResponse = {
          role: "ASSISTANT",
          message: data.response,
        };

        setConversation((prevConversation) => [
          ...prevConversation,
          aiResponse,
        ]);
      } catch (error) {
        console.error("Error getting response from AI:", error);
      } finally {
        setLoadingMessage(false);
      }
    }
  };

  const uploadFile = async () => {
    if (file) {
      const userId = localStorage.getItem("id");
      const folderName = `${userId}/documents`; // Replace with actual user ID or dynamically fetch it
      const key = `${folderName}/${file.name}`;
      // console.log(file.name);
      const params = {
        Bucket: "aiflowpro-fe-userdata",
        Key: key,
        Body: file,
      };
      try {
        const data = await s3.upload(params).promise();
        console.log("File uploaded successfully:", data);
        console.log("File uploaded successfully:", data.Location);
      } catch (err) {
        console.error("Error uploading file:", err);
      }
    }
  };

  useEffect(() => {
    if (scrollableChatRef.current) {
      scrollableChatRef.current.scrollTop =
        scrollableChatRef.current.scrollHeight;
    }
  }, [conversation]);

  // Clear chat by setting conversation to an empty array
  const handleClearMessages = () => {
    setConversation([]);
  };

  // Handle the removal of the file
  const handleRemoveFile = () => {
    setFile(null);
    URL.revokeObjectURL(file); // Clean up the Object URL
    handleClearMessages();
  };

  // Show the remove file modal
  const handleShowRemoveFileModal = () => {
    setShowRemoveFileModal(true);
  };

  // Hide the remove file modal
  const handleHideRemoveFileModal = () => {
    setShowRemoveFileModal(false);
  };

  return (
    <Container isOpen={isSidebarOpen}>
      <Header onClick={() => navigate("/")}>
        <img src={BackIcon} alt="back" />
        <Heading>Chat2doc</Heading>
      </Header>
      <ContentDiv>
        <LeftPane>
          <div style={{ display: "flex", gap: "20px", marginBottom: "0.5rem" }}>
            <LeftPaneHeader style={{ width: "100%" }}>
              <HeaderText>Ask Chat2doc Ai</HeaderText>
            </LeftPaneHeader>
            <ClearButton onClick={handleClearMessages}>Clear</ClearButton>
          </div>
          <ScrollableChat ref={scrollableChatRef}>
            {loadingFile ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
                <ChatText style={{ marginLeft: "1rem" }}>
                  AI model loading
                </ChatText>
              </div>
            ) : file ? (
              <>
                <ChatDiv>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <ChatBox>
                      <ChatText>
                        Hello! I am Chat2doc Ai. How may I assist you?
                      </ChatText>
                    </ChatBox>
                  </div>
                </ChatDiv>
                {conversation.map((message, index) =>
                  message.role === "USER" ? (
                    <UserChatDiv key={index}>
                      <UserChatBox>
                        <ChatText>{message.message}</ChatText>
                      </UserChatBox>
                    </UserChatDiv>
                  ) : (
                    <ChatDiv key={index}>
                      <ChatBox>
                        <ChatText>{message.message}</ChatText>
                      </ChatBox>
                    </ChatDiv>
                  )
                )}
                {loadingMessage && (
                  <ChatDiv>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <ChatBox>
                        <LoadingText />
                      </ChatBox>
                    </div>
                  </ChatDiv>
                )}
              </>
            ) : null}
          </ScrollableChat>
          <InputContainer>
            <Input
              type="text"
              placeholder="Ask a question"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
            />
            <img
              src={ArrowUpIcon}
              alt="send message"
              style={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={handleSendMessage}
            />
          </InputContainer>
        </LeftPane>
        <RightPane>
          {file ? (
            <PdfContainer>
              <PdfViewer file={file} />
              <img
                src={CrossIcon}
                alt="close"
                style={{
                  position: "absolute",
                  zIndex: "1000",
                  right: "5px",
                  top: "5px",
                  cursor: "pointer",
                  borderRadius: "50%",
                  opacity: "0.6",
                }}
                onClick={handleRemoveFile} // Clear file from state when clicked
              />
            </PdfContainer>
          ) : (
            <>
              <DragAndDrop
                onClick={() => document.getElementById("fileInput").click()}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <input
                  id="fileInput"
                  type="file"
                  accept="application/pdf"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <IconDiv>
                  <img src={UploadIcon} alt="upload" />
                </IconDiv>
                <UploadText>
                  Drag and drop files, or
                  <span style={{ color: "#2F80ED" }}> Browse</span>
                </UploadText>
                <SupportText>Support pdf files</SupportText>
              </DragAndDrop>
              <OrDivider>OR</OrDivider>
              <DriveFilePicker onFilesSelected={handleDriveFiles} />{" "}
            </>
          )}
        </RightPane>
      </ContentDiv>

      {showRemoveFileModal && (
        <RemovePdfModal
          onCancel={handleHideRemoveFileModal}
          onRemove={() => {
            handleRemoveFile();
            handleHideRemoveFileModal();
          }}
        />
      )}
    </Container>
  );
}
