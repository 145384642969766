import React, { useState } from "react";
import { TextField, Tooltip } from "@mui/material";
import {
  ToolBarContainer,
  ToolBarHeader,
  ToolBarBody,
  ToolBarItem,
  ToolBarFooter,
  NoActionsFound,
  ToolBarTagContainer,
  ToolBarTag,
} from "./style";
import DocumentIcon from "../../assets/workflows/tools/document-icon.svg";
import TimeIcon from "../../assets/workflows/tools/time-icon.svg";
import TodoIcon from "../../assets/workflows/tools/todo-icon.svg";
import MeetingIcon from "../../assets/workflows/tools/meeting-icon.svg";
import TaskIcon from "../../assets/workflows/tools/task-icon.svg";
import ContentIcon from "../../assets/workflows/tools/content-icon.svg";
import GeneralIcon from "../../assets/workflows/tools/general-icon.svg";
import AllIcon from "../../assets/workflows/zap-white-icon.svg";

const toolTags = [
  {
    name: "All Actions",
    icon: AllIcon,
    bgColor: "#666",
  },
  {
    name: "Document",
    icon: DocumentIcon,
    bgColor: "#FF7B00",
  },
  {
    name: "To-Do",
    icon: TodoIcon,
    bgColor: "#FF4D6B",
  },
  {
    name: "Task",
    icon: TaskIcon,
    bgColor: "#E91E63",
  },
  {
    name: "Meeting",
    icon: MeetingIcon,
    bgColor: "#E6B800",
  },
  {
    name: "Time",
    icon: TimeIcon,
    bgColor: "#4CAF50",
  },
  {
    name: "Content",
    icon: ContentIcon,
    bgColor: "#2185D0",
  },
  {
    name: "General",
    icon: GeneralIcon,
    bgColor: "#666",
  },
];

const ToolBar = ({ tools, onSelectAction }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTag, setActiveTag] = useState("All Actions");

  const filteredTools = tools.filter((tool) => {
    const searchTermLower = searchTerm.toLowerCase();
    const matchesSearch =
      tool.toolName.toLowerCase().includes(searchTermLower) ||
      tool.toolDescription.toLowerCase().includes(searchTermLower);

    const matchesTag =
      activeTag === "All Actions" || tool.toolTag === activeTag;

    return matchesSearch && matchesTag;
  });

  const handleTagClick = (tagName) => {
    setSearchTerm("");
    setActiveTag(tagName);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <ToolBarContainer style={{ width: "calc(100% - 60px)" }}>
        <ToolBarHeader>{activeTag}</ToolBarHeader>
        <ToolBarBody style={{ height: "350px" }}>
          {filteredTools.length > 0 ? (
            filteredTools.map((tool) => (
              <ToolBarItem
                key={tool.toolName}
                onClick={() => onSelectAction(tool)}
              >
                <div>{tool.toolName}</div>
                <div
                  style={{
                    fontSize: "14px",
                    color: "rgba(0, 0, 0, 0.6)",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  }}
                >
                  {tool.toolDescription}
                </div>
              </ToolBarItem>
            ))
          ) : (
            <NoActionsFound>
              <div>No actions found</div>
              <div
                style={{
                  fontSize: "14px",
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                Try searching with a different term
              </div>
            </NoActionsFound>
          )}
        </ToolBarBody>
        <ToolBarFooter>
          <TextField
            label="Search actions"
            fullWidth
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </ToolBarFooter>
      </ToolBarContainer>
      <ToolBarTagContainer>
        {toolTags.map((tag) => (
          <Tooltip key={tag.name} title={tag.name} placement="left">
            <ToolBarTag
              bgColor={tag.bgColor}
              className={activeTag === tag.name ? "active" : ""}
              onClick={() => handleTagClick(tag.name)}
            >
              <img src={tag.icon} alt={tag.name} />
            </ToolBarTag>
          </Tooltip>
        ))}
      </ToolBarTagContainer>
    </div>
  );
};

export default ToolBar;
