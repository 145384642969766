import React, { useEffect } from "react";
import {
  Popover,
  // TextField,
  // Autocomplete,
  // Tooltip,
  // TextareaAutosize,
  // RadioGroup,
  // FormControlLabel,
  // Radio,
  // Paper,
} from "@mui/material";
import {
  PopoverContent,
  // SaveButton,
  // PopoverHdr,
  // PopoverMainHdr,
  // Tabs,
  // Tab,
} from "./style";
// import DeleteIcon from "../../assets/workflows/delete-icon.svg";
// import SparklesIcon from "../../assets/workflows/sparkles-icon.svg";
import ToolBar from "./toolBar";
import ToolConfiguration from "./toolConfiguration";

const Popovers = ({ anchorEl, treeData, tools, node, onClose, onSave }) => {
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [selectedAction, setSelectedAction] = React.useState(null);
  const [nameError, setNameError] = React.useState(false);
  const [descriptionError, setDescriptionError] = React.useState(false);
  const [configValues, setConfigValues] = React.useState({});
  const [configTypes, setConfigTypes] = React.useState({});
  const [configErrors, setConfigErrors] = React.useState({});
  const [selectedOptions, setSelectedOptions] = React.useState({});

  const [section, setSection] = React.useState("ToolBar");

  useEffect(() => {
    if (Object.keys(node?.formData).length > 0) {
      setSection("Config");
    }
  }, [node]);

  React.useEffect(() => {
    if (node?.formData) {
      setName(node.formData.name || "");
      setDescription(node.formData.description || "");

      const matchedTool = tools.find(
        (tool) => tool.toolName === node.formData.name
      );
      setSelectedAction(matchedTool || null);

      if (
        node.formData.description &&
        !node.img.match(/ConditionIcon|TriggerIcon/)
      ) {
        try {
          const parsedConfig = JSON.parse(node.formData.description);
          const newConfigValues = {};
          const newConfigTypes = {};
          const newSelectedOptions = {};

          parsedConfig.forEach((item) => {
            if (item.type) {
              newConfigValues[item.title] = item.content;
              newConfigTypes[item.title] = item.type;
            } else {
              newSelectedOptions[item.title] = item.content;
            }
          });

          setConfigValues(newConfigValues);
          setConfigTypes(newConfigTypes);
          setSelectedOptions(newSelectedOptions);
        } catch (error) {
          console.error("Error parsing configuration:", error);
        }
      }
    }
  }, [node, tools]);

  const handleSave = () => {
    let hasError = false;
    if (node?.img !== "ConditionIcon" && node?.img !== "TriggerIcon") {
      if (!name) {
        setNameError(true);
        hasError = true;
      } else {
        setNameError(false);
      }

      const newConfigErrors = {};
      if (selectedAction) {
        JSON.parse(selectedAction.configuration).forEach((config) => {
          if (
            config.required &&
            !configValues[config.title] &&
            !selectedOptions[config.title]
          ) {
            newConfigErrors[config.title] = true;
            hasError = true;
          }
        });
      }
      setConfigErrors(newConfigErrors);
    }

    if (!description) {
      setDescriptionError(true);
      hasError = true;
    } else {
      setDescriptionError(false);
    }

    if (hasError) return;

    const newFormData =
      node?.img === "ConditionIcon"
        ? { name: "Condition", description: description }
        : node?.img === "TriggerIcon"
        ? { name: "Trigger", description: description }
        : {
            name: selectedAction.toolName,
            description: JSON.stringify(
              Object.entries(configValues)
                .map(([title, content]) => ({
                  title,
                  type: configTypes[title],
                  content: configTypes[title] === "Dynamic" ? "" : content,
                }))
                .concat(
                  Object.entries(selectedOptions).map(([title, option]) => ({
                    title,
                    content: option,
                  }))
                )
            ),
            isSkill: selectedAction.isSkill,
            prompt: selectedAction.prompt ? selectedAction.prompt : "",
          };

    console.log("newFormData", newFormData);
    onSave(newFormData, node?.formData ? "Update" : "Add");
  };

  const handleActionChange = (value) => {
    setSelectedAction(value);
    setName(value?.toolName);
    setDescription(value?.toolDescription);
    setConfigValues({});
    setConfigTypes({});
    setNameError(false);
    setDescriptionError(false);
    setConfigErrors({});
    setSelectedOptions({});
    setSection("Config");
  };

  // const handleConfigChange = (title, value) => {
  //   setConfigValues((prev) => ({
  //     ...prev,
  //     [title]: value,
  //   }));
  //   setConfigErrors((prev) => ({
  //     ...prev,
  //     [title]: false,
  //   }));
  // };

  // const handleOptionChange = (title, value) => {
  //   setSelectedOptions((prev) => ({
  //     ...prev,
  //     [title]: value,
  //   }));
  //   setConfigErrors((prev) => ({
  //     ...prev,
  //     [title]: false,
  //   }));
  // };

  // const handleConfigTypeChange = (title, type, config) => {
  //   setConfigTypes((prev) => ({
  //     ...prev,
  //     [title]: type,
  //   }));

  //   // Clear value and errors when switching to Use AI
  //   if (type === "Use AI") {
  //     setConfigValues((prev) => ({
  //       ...prev,
  //       [title]: "",
  //     }));
  //     setConfigErrors((prev) => ({
  //       ...prev,
  //       [title]: false,
  //     }));
  //   }

  //   // Set hint as value when switching to Dynamic
  //   if (type === "Dynamic") {
  //     setConfigValues((prev) => ({
  //       ...prev,
  //       [title]: config.hint,
  //     }));
  //     setConfigErrors((prev) => ({
  //       ...prev,
  //       [title]: false,
  //     }));
  //   }
  // };

  const handleDelete = () => {
    onClose("Delete");
  };

  // const renderContent = () => {
  //   if (node.img === "EndIcon") {
  //     return (
  //       <PopoverMainHdr>
  //         <PopoverHdr style={{ marginBottom: "1.44rem" }}>End Info</PopoverHdr>
  //         <Tooltip title="Delete" placement="top">
  //           <img src={DeleteIcon} alt="Delete" onClick={handleDelete} />
  //         </Tooltip>
  //       </PopoverMainHdr>
  //     );
  //   }

  //   return (
  //     <>
  //       <PopoverMainHdr>
  //         <PopoverHdr style={{ marginBottom: "1rem" }}>
  //           {node?.img === "ConditionIcon"
  //             ? "Condition Info"
  //             : node?.img === "TriggerIcon"
  //             ? "Trigger Info"
  //             : "Set your action"}
  //         </PopoverHdr>
  //         {node?.img !== "TriggerIcon" && (
  //           <Tooltip title="Delete" placement="top">
  //             <img src={DeleteIcon} alt="Delete" onClick={handleDelete} />
  //           </Tooltip>
  //         )}
  //       </PopoverMainHdr>
  //       {node?.img !== "ConditionIcon" && node?.img !== "TriggerIcon" && (
  //         <>
  //           <PopoverHdr
  //             style={{
  //               fontSize: "16px",
  //               fontWeight: "600",
  //               marginBottom: "0.4rem",
  //             }}
  //           >
  //             Select from list of actions
  //           </PopoverHdr>
  //           <Autocomplete
  //             options={tools}
  //             getOptionLabel={(option) => `${option.toolName}`}
  //             value={selectedAction}
  //             onChange={handleActionChange}
  //             fullWidth
  //             renderOption={(props, option) => (
  //               <li {...props}>
  //                 <div style={{ padding: "4px 0" }}>
  //                   <div style={{ fontWeight: 500 }}>{option.toolName}</div>
  //                   <div
  //                     style={{
  //                       fontSize: "14px",
  //                       color: "rgba(0, 0, 0, 0.6)",
  //                       marginTop: "4px",
  //                       display: "-webkit-box",
  //                       WebkitLineClamp: "2",
  //                       WebkitBoxOrient: "vertical",
  //                       overflow: "hidden",
  //                     }}
  //                   >
  //                     {option.toolDescription}
  //                   </div>
  //                 </div>
  //               </li>
  //             )}
  //             renderInput={(params) => (
  //               <TextField
  //                 {...params}
  //                 label="Choose an action"
  //                 error={nameError}
  //                 helperText={nameError ? "Action name is required" : ""}
  //               />
  //             )}
  //           />
  //           {selectedAction && (
  //             <p
  //               style={{
  //                 marginTop: "8px",
  //                 fontSize: "14px",
  //                 marginBottom: "1rem",
  //               }}
  //             >
  //               {selectedAction.toolDescription}
  //             </p>
  //           )}
  //           {selectedAction &&
  //             JSON.parse(selectedAction.configuration).map((config, index) => (
  //               <div key={index} style={{ marginBottom: "1rem" }}>
  //                 <PopoverHdr
  //                   style={{
  //                     fontSize: "16px",
  //                     fontWeight: "600",
  //                   }}
  //                 >
  //                   {config.title}
  //                 </PopoverHdr>
  //                 <p
  //                   style={{
  //                     fontSize: "14px",
  //                     marginTop: "unset",
  //                     marginBottom: "0.4rem",
  //                   }}
  //                 >
  //                   {config.description}
  //                 </p>
  //                 {config.type !== "Dropdown" && (
  //                   <Tabs
  //                     value={configTypes[config.title] || "Static"}
  //                     onChange={(e, newValue) =>
  //                       handleConfigTypeChange(config.title, newValue, config)
  //                     }
  //                   >
  //                     <Tab label="Use AI" value="Use AI" />
  //                     <Tab label="Dynamic" value="Dynamic" />
  //                     <Tab label="Static" value="Static" />
  //                   </Tabs>
  //                 )}
  //                 {config.type === "Text Field" ? (
  //                   <TextField
  //                     fullWidth
  //                     placeholder={
  //                       configTypes[config.title] === "Use AI"
  //                         ? "Instructions to pick up from flow"
  //                         : config.hint
  //                     }
  //                     value={configValues[config.title] || ""}
  //                     onChange={(e) =>
  //                       handleConfigChange(config.title, e.target.value)
  //                     }
  //                     error={configErrors[config.title]}
  //                     helperText={
  //                       configErrors[config.title]
  //                         ? "This field is required"
  //                         : ""
  //                     }
  //                     disabled={configTypes[config.title] === "Dynamic"}
  //                     sx={{
  //                       "& .MuiInputBase-root": {
  //                         borderRadius: "1rem",
  //                       },
  //                       "& .MuiOutlinedInput-input": {
  //                         padding: "11px 16px",
  //                       },
  //                     }}
  //                   />
  //                 ) : config.type === "Text Area" ? (
  //                   <TextareaAutosize
  //                     minRows={3}
  //                     placeholder={
  //                       configTypes[config.title] === "Use AI"
  //                         ? "Instructions to pick up from flow"
  //                         : config.hint
  //                     }
  //                     value={configValues[config.title] || ""}
  //                     onChange={(e) =>
  //                       handleConfigChange(config.title, e.target.value)
  //                     }
  //                     disabled={configTypes[config.title] === "Dynamic"}
  //                     style={{
  //                       width: "-webkit-fill-available",
  //                       padding: "11px 16px",
  //                       borderRadius: "1rem",
  //                       border: configErrors[config.title]
  //                         ? "1px solid #d32f2f"
  //                         : "1px solid rgba(0, 0, 0, 0.23)",
  //                       fontFamily: "inherit",
  //                     }}
  //                   />
  //                 ) : (
  //                   config.type === "Dropdown" && (
  //                     <RadioGroup
  //                       value={selectedOptions[config.title] || ""}
  //                       onChange={(e) =>
  //                         handleOptionChange(config.title, e.target.value)
  //                       }
  //                     >
  //                       {config.options.map((option) => (
  //                         <FormControlLabel
  //                           key={option}
  //                           value={option}
  //                           control={<Radio />}
  //                           label={option}
  //                         />
  //                       ))}
  //                     </RadioGroup>
  //                   )
  //                 )}
  //                 {configErrors[config.title] && config.type === "Dropdown" && (
  //                   <p
  //                     style={{
  //                       color: "#d32f2f",
  //                       fontSize: "0.75rem",
  //                       marginTop: "3px",
  //                     }}
  //                   >
  //                     This field is required
  //                   </p>
  //                 )}
  //               </div>
  //             ))}
  //         </>
  //       )}
  //       {node?.img === "ConditionIcon" || node?.img === "TriggerIcon" ? (
  //         <>
  //           <PopoverHdr
  //             style={{
  //               fontSize: "16px",
  //               fontWeight: "600",
  //               marginBottom: "0.4rem",
  //             }}
  //           >
  //             <div
  //               style={{
  //                 display: "flex",
  //                 alignItems: "center",
  //                 gap: "0.5rem",
  //                 justifyContent: "space-between",
  //               }}
  //             >
  //               {node?.img === "ConditionIcon"
  //                 ? "Enter a condition with yes or no answers."
  //                 : "Enter a scenario where you want to trigger this workflow."}
  //               {node?.img === "ConditionIcon" && (
  //                 <Tooltip title="Get Suggestions from AI" placement="top">
  //                   <img
  //                     style={{ cursor: "pointer" }}
  //                     src={SparklesIcon}
  //                     alt="Sparkles"
  //                   />
  //                 </Tooltip>
  //               )}
  //             </div>
  //           </PopoverHdr>
  //           <TextField
  //             placeholder={
  //               node?.img === "ConditionIcon"
  //                 ? "Are there any items in my todo list?"
  //                 : "When I inquire about fetching news articles during a conversation"
  //             }
  //             fullWidth
  //             value={description}
  //             onChange={(e) => {
  //               setDescription(e.target.value);
  //               setDescriptionError(false);
  //             }}
  //             sx={{
  //               "& .MuiInputBase-root": {
  //                 borderRadius: "1rem",
  //               },
  //               "& .MuiOutlinedInput-input": {
  //                 padding: "11px 16px",
  //               },
  //             }}
  //             error={descriptionError}
  //             helperText={descriptionError ? "Description is required" : ""}
  //           />
  //         </>
  //       ) : null}
  //       <SaveButton
  //         variant="contained"
  //         fullWidth
  //         style={{ marginTop: "1rem", borderRadius: "1rem" }}
  //         onClick={handleSave}
  //       >
  //         Save Info
  //       </SaveButton>
  //     </>
  //   );
  // };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      slotProps={{
        paper: {
          sx: {
            borderRadius: "1rem",
            boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.08)",
          },
        },
      }}
    >
      <PopoverContent>
        {section === "ToolBar" && node?.img === "ZapIcon" ? (
          <ToolBar tools={tools} onSelectAction={handleActionChange} />
        ) : (
          <ToolConfiguration
            treeData={treeData}
            node={node}
            selectedAction={selectedAction}
            handleDelete={handleDelete}
            description={description}
            setDescription={setDescription}
            descriptionError={descriptionError}
            setDescriptionError={setDescriptionError}
            configValues={configValues}
            setConfigValues={setConfigValues}
            configTypes={configTypes}
            setConfigTypes={setConfigTypes}
            configErrors={configErrors}
            setConfigErrors={setConfigErrors}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            onClose={onClose}
            onSave={handleSave}
          />
        )}
      </PopoverContent>
    </Popover>
  );
};

export default Popovers;
