import React, { useState, useEffect } from "react";
import {
  IntegrationCard,
  AuthorizedTitle,
  SubText,
  AuthorizeButton,
} from "./style";
import Check from "../../assets/Check.svg";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import TransitionsModal from "../modals/customEmailPopup";
import { toast } from "react-toastify";
import DeleteIcon from "../../assets/delete-grey.svg";

const CustomEmail = ({ integration, index, provider, refresh }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isHide, setIsHide] = useState(false);
  const [user, setUser] = useState(null);
  const [open, setOpen] = useState(false);

  const handleUserLogin = async () => {
    const payload = {
      email: localStorage.getItem("email"),
      password: localStorage.getItem("password"),
    };
    console.log("Payload", payload);
    try {
      const res = await axios.post(
        `https://api.emailflowpro.io/api/user/login`,
        payload
      );
      console.log("User Login Response", res.data);
      setUser(res.data);
    } catch (error) {
      console.log("User Login Error", error);
    }
  };

  useEffect(() => {
    handleUserLogin();
  }, []);

  useEffect(() => {
    handleUserLogin();
  }, []);

  useEffect(() => {
    if (!provider?.length) {
      return;
    }

    setIsLoading(false);

    const emailProvider = provider[0]?.email_provider;
    if (
      emailProvider !== "oAuthGoogle" &&
      emailProvider !== "oAuthMicrosoft" &&
      emailProvider !== "none"
    ) {
      setIsAuthorized(true);
    } else if (
      emailProvider === "oAuthGoogle" ||
      emailProvider === "oAuthMicrosoft"
    ) {
      setIsHide(true);
    } else {
      setIsAuthorized(false);
      setIsHide(false);
    }
  }, [provider]);

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const res = await axios.delete(
        `https://api.emailflowpro.io/api/provider/delete/${provider[0]?.id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      console.log("Delete Gmail Response", res.data);
      toast.success(res.message);
      refresh();
      setIsAuthorized(false);
    } catch (error) {
      console.log("Delete Gmail Error", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <IntegrationCard
        key={index}
        style={{
          background: isHide ? "beige" : "#f4f6f6",
          opacity: isHide ? "0.5" : "1",
        }}
      >
        <AuthorizedTitle>
          <span
            style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
          >
            <span>
              <img src={integration.icon} alt="icon" />
            </span>
            {integration.name}
          </span>
          {/* {isAuthorized && <img src={Check} alt="check" />} */}
          {isAuthorized && (
            <img
              src={DeleteIcon}
              alt="delete"
              onClick={!isLoading && handleDelete}
            />
          )}
        </AuthorizedTitle>
        <SubText>{integration.description}</SubText>
        <AuthorizeButton
          onClick={() => {
            setOpen(true);
            setIsLoading(true);
          }}
          disabled={isLoading || isAuthorized || isHide}
          isAuthorized={isAuthorized}
        >
          {isLoading ? (
            <CircularProgress size={13} color="inherit" />
          ) : isAuthorized ? (
            "Authorized"
          ) : (
            "Authorize"
          )}
        </AuthorizeButton>
      </IntegrationCard>
      <TransitionsModal
        open={open}
        user={user}
        handleClose={() => setOpen(false)}
        refresh={refresh}
      />
    </>
  );
};

export default CustomEmail;
