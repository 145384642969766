import axios from "axios";

export const GoogleCalendarUrl = {
  TRANSCRIBER_API_URL: "https://api.transcriber.aiflowpro.io/api/",
  GOOGLE_CALENDAR_CLIENT_ID:
    "887774058269-bme53gbk39cprnn058bb0ja254sk71p7.apps.googleusercontent.com",
  REDIRECT_URI: "https://app.aiflowpro.io/",
};

const endpoints = {
  authGoogle: "/user/ai-flow-pro-auth-google",
  checkGoogleToken: "/user/ai-flow-pro-check-google-token",
  getGoogleTokenAndUserInfo: "/user/ai-flow-pro-get-google-token-and-user-info",
  deleteGoogleToken: "/user/delete-google-token",
};

export const authGoogle = async (payload) => {
  let isSuccess;
  let response;
  try {
    const res = await axios.post(
      `${GoogleCalendarUrl.TRANSCRIBER_API_URL}${endpoints.authGoogle}`,
      payload
    );
    console.log(res.data);
    if (res.data.responseCode === 200) {
      response = res.data;
      isSuccess = true;
    } else {
      isSuccess = false;
      response = res.data;
    }
  } catch (error) {
    isSuccess = false;
    console.log("Auth google API Error:", error.message);
  } finally {
    return {
      isSuccess,
      data: response,
    };
  }
};

export const checkGoogleToken = async (payload) => {
  let isSuccess;
  let response;
  try {
    const res = await axios.get(
      `${GoogleCalendarUrl.TRANSCRIBER_API_URL}${endpoints.checkGoogleToken}`,
      {
        params: payload,
      }
    );
    console.log(res.data);
    if (res.data.responseCode === 200) {
      response = res.data;
      isSuccess = true;
    } else {
      isSuccess = false;
      response = res.data;
    }
  } catch (error) {
    isSuccess = false;
    console.log("Check google token API Error:", error.message);
  } finally {
    return {
      isSuccess,
      data: response,
    };
  }
};

export const deleteGoogleToken = async (payload) => {
  let isSuccess;
  let response;
  try {
    const res = await axios.delete(
      `${GoogleCalendarUrl.TRANSCRIBER_API_URL}${endpoints.deleteGoogleToken}`,
      {
        params: payload,
      }
    );
    console.log(res.data);
    if (res.data.responseCode === 200) {
      response = res.data;
      isSuccess = true;
    } else {
      isSuccess = false;
      response = res.data;
    }
  } catch (error) {
    isSuccess = false;
    console.log("Delete google token API Error:", error.message);
  } finally {
    return {
      isSuccess,
      data: response,
    };
  }
};

export const getGoogleTokenAndUserInfo = async (payload) => {
  let isSuccess;
  let response;
  try {
    const res = await axios.post(
      `${GoogleCalendarUrl.TRANSCRIBER_API_URL}${endpoints.getGoogleTokenAndUserInfo}`,
      payload
    );
    console.log(res.data);

    if (res.data.responseCode === 200) {
      response = res.data;
      isSuccess = true;
    } else {
      isSuccess = false;
      response = res.data;
    }
  } catch (error) {
    isSuccess = false;
    console.log("Get google token and user info API Error:", error.message);
  } finally {
    return {
      isSuccess,
      data: response,
    };
  }
};
