import React, { useEffect, useState } from "react";
import Drive from "../../assets/Gdrive.png";
import { DriveUploadBtn } from "./Style";
import { message } from "antd";

function DriveFileUploader({ file, fileName, onUploadComplete }) {
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [expiresIn, setExpiresIn] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    const storedAccessToken = localStorage.getItem("access_token");
    const storedRefreshToken = localStorage.getItem("refresh_token");
    const storedExpiresIn = localStorage.getItem("expires_in");

    if (storedAccessToken && storedRefreshToken) {
      setAccessToken(storedAccessToken);
      setRefreshToken(storedRefreshToken);
      setExpiresIn(storedExpiresIn);
    }
  }, []);

  const refreshAccessToken = async () => {
    if (isRefreshing || !refreshToken) return;
    setIsRefreshing(true);
    try {
      const response = await fetch("https://oauth2.googleapis.com/token", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
          refresh_token: refreshToken,
          grant_type: "refresh_token",
        }),
      });

      const data = await response.json();
      if (!data.error) {
        const { access_token, expires_in } = data;
        setAccessToken(access_token);
        setExpiresIn(expires_in);
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("expires_in", expires_in);
      } else {
        console.error("Error refreshing access token:", data.error);
      }
    } catch (error) {
      console.error("Error refreshing access token:", error);
    } finally {
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    if (expiresIn) {
      refreshAccessToken();
    }
  }, [expiresIn]);

  const handleFileUpload = async () => {
    if (!file) return;
    try {
      let fileToUpload;

      // Check if the file is a Blob URL
      if (typeof file === "string" && file.startsWith("blob:")) {
        const response = await fetch(file);
        fileToUpload = await response.blob();
      } else {
        fileToUpload = file; // Assume it's already a File or Blob
      }
      const metadata = {
        name: fileName || file.name,
        mimeType: "application/pdf",
      };
      const form = new FormData();
      form.append(
        "metadata",
        new Blob([JSON.stringify(metadata)], { type: "application/json" })
      );
      form.append("file", fileToUpload);

      const response = await fetch(
        "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
        {
          method: "POST",
          headers: { Authorization: `Bearer ${accessToken}` },
          body: form,
        }
      );

      const data = await response.json();
      if (!data.error) {
        onUploadComplete && onUploadComplete(data);
        message.success("File uploaded");
      } else {
        console.error("Error uploading file:", data.error);
        message.error("Failed to upload file");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      message.error("Failed to upload file");
    }
  };

  const handleOpenPicker = () => {
    if (!accessToken || !refreshToken) {
      const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_SUMMARIZE_REDIRECT_URI}&scope=https://www.googleapis.com/auth/drive.file&access_type=offline&prompt=consent`;
      window.location.href = authUrl;
    } else {
      handleFileUpload();
    }
  };

  return (
    <DriveUploadBtn onClick={handleOpenPicker}>
      <img src={Drive} alt="Google Drive" height={16} width={16} />
      <span>Upload to Drive</span>
    </DriveUploadBtn>
  );
}

export default DriveFileUploader;
