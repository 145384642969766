import React, { useState, useEffect } from "react";
import {
  IntegrationCard,
  AuthorizedTitle,
  SubText,
  AuthorizeButton,
} from "./style";
import Check from "../../assets/Check.svg";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import DeleteIcon from "../../assets/delete-grey.svg";

const Microsoft = ({ integration, index, user, provider, refresh }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isHide, setIsHide] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  const handleUserLogin = async () => {
    const payload = {
      email: localStorage.getItem("email"),
      password: localStorage.getItem("password"),
    };
    try {
      const res = await axios.post(
        `https://api.emailflowpro.io/api/user/login`,
        payload
      );
      console.log("User Login Response", res.data);
      setUser(res.data);
    } catch (error) {
      console.log("User Login Error", error);
    }
  };

  useEffect(() => {
    handleUserLogin();
  }, []);

  useEffect(() => {
    if (!provider?.length) {
      return;
    }

    setIsLoading(false);

    const emailProvider = provider[0]?.email_provider;
    if (emailProvider === "oAuthMicrosoft") {
      setIsAuthorized(true);
    } else if (emailProvider !== "none") {
      setIsHide(true);
    } else {
      setIsAuthorized(false);
      setIsHide(false);
    }
  }, [provider]);

  let microsoftAuthWindow = null;
  const handleMicrosoftAuth = async () => {
    try {
      setIsLoading(true);

      // Open the auth URL in a new tab
      microsoftAuthWindow = window.open(
        "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=33e2d6a4-3f27-44c5-9061-ddca9c7d7d77&response_type=code&scope=openid%20IMAP.AccessAsUser.All%20offline_access%20SMTP.Send%20User.Read%20email%20Mail.Send",
        "_blank",

        "width=600,height=600"
      );
    } catch (error) {
      console.error("Error connecting with Microsoft:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleMessage = async (event) => {
      console.log("event.data", event.data);
      //   if (event.origin !== window.location.origin) return;
      if (event.data.type === "MICROSOFT_OAUTH_CALLBACK") {
        const { accessToken, refreshToken, userDetails } = event.data;
        if (accessToken && refreshToken && userDetails) {
          console.log("accessToken:", accessToken);
          console.log("refreshToken:", refreshToken);
          console.log("userDetails:", userDetails);
          await createMicrosoft(accessToken, refreshToken, userDetails);
          // If needed, close the popup (just in case):
          if (microsoftAuthWindow) {
            microsoftAuthWindow.close();
          }
        }
      }
    };
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const createMicrosoft = async (accessToken, refreshToken, userDetails) => {
    const requestBody = {
      email_provider: "oAuthMicrosoft",
      userId: user.id,
      name: userDetails.name,
      email: userDetails.email,
      password: accessToken,
      imap_hostname: refreshToken,
    };
    try {
      const res = await axios.post(
        `https://api.emailflowpro.io/api/provider/createGmail`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      console.log("Create Microsoft Response", res.data);
      //   if (res.data.emailProvider?.email_provider === "oAuthMicrosoft") {
      //     setIsAuthorized(true);
      //     setIsLoading(false);
      //   }
      refresh();
    } catch (error) {
      console.log("Create Microsoft Error", error);
      setIsAuthorized(false);
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const res = await axios.delete(
        `https://api.emailflowpro.io/api/provider/delete/${provider[0]?.id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      console.log("Delete Gmail Response", res.data);
      toast.success(res.message);
      refresh();
      setIsAuthorized(false);
    } catch (error) {
      console.log("Delete Gmail Error", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <IntegrationCard
        key={index}
        style={{
          background: isHide ? "beige" : "#f4f6f6",
          opacity: isHide ? "0.5" : "1",
        }}
      >
        <AuthorizedTitle>
          <span
            style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
          >
            <span>
              <img src={integration.icon} alt="icon" />
            </span>
            {integration.name}
          </span>
          {/* {isAuthorized && <img src={Check} alt="check" />} */}
          {isAuthorized && (
            <img
              src={DeleteIcon}
              alt="delete"
              onClick={!isLoading && handleDelete}
            />
          )}
        </AuthorizedTitle>
        <SubText>{integration.description}</SubText>
        <AuthorizeButton
          onClick={() => {
            handleMicrosoftAuth();
            setIsLoading(true);
          }}
          disabled={isLoading || isAuthorized || isHide}
          isAuthorized={isAuthorized}
        >
          {isLoading ? (
            <CircularProgress size={13} color="inherit" />
          ) : isAuthorized ? (
            "Authorized"
          ) : (
            "Authorize"
          )}
        </AuthorizeButton>
      </IntegrationCard>
    </>
  );
};

export default Microsoft;
