import React from 'react'
import { Container, ContentDiv, LoginBox, Title, FormText, SignInButton } from '../../Pages/Auth/Style'
import CircularProgress from '@mui/material/CircularProgress';

const StatusDisplay = ({imgSrc, handleClick, title, text, btnText, width="", bg='#fdfdfd', height='80%', bs='0px 30px 40px 0px rgba(220, 220, 220, 0.2)', innerWidth='95%', isLoading=false}) => {
  return (
    <Container style={{width:width, backgroundColor:bg}}>

        <LoginBox id='popup-card' style={{height:height, boxShadow:bs, width:innerWidth}}>
            <ContentDiv style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '62%'}}>
                <img src={imgSrc} alt="img" />
                <Title style={{marginBottom: '0.63rem', textAlign: 'center'}}>{title}</Title>
                <FormText style={{textAlign: 'center'}}>{text}</FormText>
                <SignInButton style={{width: '80%', marginTop: '2.5rem'}} onClick={handleClick} disabled={isLoading}>{isLoading ? <CircularProgress size={21} color="inherit"/> : btnText}</SignInButton>                    
            </ContentDiv>
        </LoginBox>
    </Container>
  )
}

export default StatusDisplay