import React, { useState } from "react";
import ZapIconWhite from "../../assets/workflows/zap-white-icon.svg";
import ZapIconGrey from "../../assets/workflows/zap-grey-icon.svg";
import ConditionIconWhite from "../../assets/workflows/condition-white-icon.svg";
import ConditionIconGrey from "../../assets/workflows/condition-grey-icon.svg";
import EndIconGrey from "../../assets/workflows/end-grey-icon.svg";
import EndIconWhite from "../../assets/workflows/end-white-icon.svg";
import {
  ToolBarContainer,
  ToolBarHeader,
  ToolBarBody,
  Footer,
  FooterMenuItem,
} from "./style";
import { Popover } from "@mui/material";

const TriggerPopovers = ({
  anchorElTrigger,
  onChooseTrigger,
  onCloseTrigger,
}) => {
  const [footerMenu, setFooterMenu] = useState([
    {
      name: "Action",
      isActive: true,
      icon: [ZapIconWhite, ZapIconGrey],
    },
    {
      name: "Condition",
      isActive: false,
      icon: [ConditionIconWhite, ConditionIconGrey],
    },
    {
      name: "End",
      isActive: false,
      icon: [EndIconWhite, EndIconGrey],
    },
  ]);

  const handleFooterMenuItemClick = (item) => {
    onChooseTrigger(item);
  };

  return (
    <Popover
      open={Boolean(anchorElTrigger)}
      anchorEl={anchorElTrigger}
      onClose={onCloseTrigger}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      slotProps={{
        paper: {
          sx: {
            borderRadius: "1rem",
            boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.08)",
          },
        },
      }}
    >
      <ToolBarContainer>
        <ToolBarHeader>All Actions</ToolBarHeader>
        <ToolBarBody
          style={{
            padding: "16px",
            display: "flex",
            flexDirection: "row",
            gap: "16px",
          }}
        >
          {footerMenu.map((item, index) => (
            <div key={item.id}>
              <Footer
                isActive={item.isActive}
                onClick={() => handleFooterMenuItemClick(item)}
              >
                <FooterMenuItem>
                  <img src={item.icon[0]} alt={item.name} />
                </FooterMenuItem>
                <div>{item.name}</div>
              </Footer>
            </div>
          ))}
        </ToolBarBody>
      </ToolBarContainer>
    </Popover>
  );
};

export default TriggerPopovers;
