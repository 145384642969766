import styled from "styled-components";
import Button from "@mui/material/Button";

export const IntegrationsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20.375rem, 1fr));
  gap: 1rem;
  width: 100%;
`;

export const IntegrationCard = styled.div`
  padding: 1rem;
  border-radius: 0.625rem;
  border: 0px solid #030303;
  background: #f4f6f6;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const CardTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #171725;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem; /* 122.222% */
  letter-spacing: 0.00625rem;
`;

export const AuthorizedTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #171725;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem; /* 122.222% */
  letter-spacing: 0.00625rem;

  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    filter: invert(0);
    &:hover {
      filter: invert(1);
    }
  }
`;

export const AuthorizeButton = styled.button`
  margin-top: auto;
  border-radius: 0.625rem;
  background: ${(props) => (props.isAuthorized ? "#3ED598" : "#007BFF")};
  color: white;
  border: none;
  padding: 0.75rem;
  cursor: pointer;
  color: #fafafb;
  text-align: center;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  &:hover {
    background: ${(props) => (props.isAuthorized ? "#3ED598" : "#3381ff")};
  }
`;

export const SubText = styled.div`
  color: #8b9ab1;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem; /* 128.571% */
  letter-spacing: 0.00625rem;
`;

//email form styles

export const Title = styled.div`
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Label = styled.label`
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
`;

export const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

export const SubmitButton = styled(Button)`
  width: fit-content;
  @media (max-width: 768px) {
    width: 100%;
  }
  background: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  float: right;
  margin-top: 1rem;

  &:hover {
    opacity: 0.9;
  }
`;
