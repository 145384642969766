import * as React from "react";
import styled from "styled-components";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
  Title,
  FormGroup,
  Label,
  SubmitButton,
  Grid,
} from "../Integrations/style";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

const FormContainer = styled.form`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const emailProvider = [
  {
    name: "Google",
    IMAPHostname: "imap.gmail.com",
    IMAPPort: 993,
    SMTPHostname: "smtp.gmail.com",
    SMTPPort: 465,
  },
  {
    name: "Outlook",
    IMAPHostname: "outlook.office365.com",
    IMAPPort: 993,
    SMTPHostname: "smtp.office365.com",
    SMTPPort: 587,
  },
  {
    name: "Yahoo",
    IMAPHostname: "imap.mail.yahoo.com",
    IMAPPort: 993,
    SMTPHostname: "smtp.mail.yahoo.com",
    SMTPPort: 587,
  },
  {
    name: "GoDaddy",
    IMAPHostname: "imap.secureserver.net",
    IMAPPort: 993,
    SMTPHostname: "smtpout.secureserver.net",
    SMTPPort: 587,
  },
  {
    name: "Apple",
    IMAPHostname: "imap.mail.me.com",
    IMAPPort: 993,
    SMTPHostname: "smtp.mail.me.com",
    SMTPPort: 587,
  },
  {
    name: "AOL",
    IMAPHostname: "imap.aol.com",
    IMAPPort: 993,
    SMTPHostname: "smtp.aol.com",
    SMTPPort: 587,
  },
  {
    name: "Mail.ru",
    IMAPHostname: "imap.mail.ru",
    IMAPPort: 993,
    SMTPHostname: "smtp.mail.ru",
    SMTPPort: 465,
  },
  {
    name: "Custom",
    IMAPHostname: "",
    IMAPPort: "",
    SMTPHostname: "",
    SMTPPort: "",
  },
];

export default function TransitionsModal({ open, user, handleClose, refresh }) {
  const [selectedProvider, setSelectedProvider] = React.useState(
    emailProvider[0]
  );
  const [formData, setFormData] = React.useState({
    provider: emailProvider[0].name,
    name: "",
    email: "",
    password: "",
    IMAPHostname: emailProvider[0].IMAPHostname || "",
    IMAPPort: emailProvider[0].IMAPPort?.toString() || "",
    SMTPHostname: emailProvider[0].SMTPHostname || "",
    SMTPPort: emailProvider[0].SMTPPort?.toString() || "",
  });

  const [isLoading, setIsLoading] = React.useState(false);

  const handleProviderChange = (event) => {
    const provider = emailProvider.find((p) => p.name === event.target.value);
    if (provider) {
      setSelectedProvider(provider);
      setFormData({
        provider: provider.name,
        name: "",
        email: "",
        password: "",
        IMAPHostname: provider.IMAPHostname || "",
        IMAPPort: provider.IMAPPort?.toString() || "",
        SMTPHostname: provider.SMTPHostname || "",
        SMTPPort: provider.SMTPPort?.toString() || "",
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const encryptedPassword = await encrypt(formData.password);
    const payload = {
      userId: user.id,
      email_provider: formData.provider,
      name: formData.name,
      email: formData.email,
      password: encryptedPassword,
      imap_hostname: formData.IMAPHostname,
      imap_port: parseInt(formData.IMAPPort),
      smtp_hostname: formData.SMTPHostname,
      smtp_port: parseInt(formData.SMTPPort),
    };

    console.log(payload);

    try {
      const res = await axios.post(
        `https://api.emailflowpro.io/api/provider/createGmail`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      console.log("Create Gmail Response", res.data);
      refresh();
      handleClose();
    } catch (error) {
      console.log("Create Gmail Error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const encrypt = async (password) => {
    const payload = {
      data: password,
    };
    const response = await axios.post(
      "https://mail-handler.dialect-ai.com/encrypt",
      payload
    );
    return response.data.encrypted;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <FormContainer onSubmit={handleSubmit}>
          <Title>Email Integration</Title>

          <FormGroup>
            <Label>Select Email Provider</Label>
            <Select
              fullWidth
              value={formData.provider}
              onChange={handleProviderChange}
              size="small"
            >
              {emailProvider.map((provider) => (
                <option key={provider.name} value={provider.name}>
                  {provider.name}
                </option>
              ))}
            </Select>
          </FormGroup>

          <FormGroup>
            <Label>Name</Label>
            <TextField
              fullWidth
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              placeholder="Enter your name"
              size="small"
            />
          </FormGroup>

          <Grid>
            <FormGroup>
              <Label>IMAP Hostname</Label>
              <TextField
                fullWidth
                type="text"
                name="IMAPHostname"
                value={formData.IMAPHostname}
                onChange={handleInputChange}
                required
                placeholder="Enter your IMAP Hostname"
                size="small"
              />
            </FormGroup>

            <FormGroup>
              <Label>IMAP Port</Label>
              <TextField
                fullWidth
                type="number"
                name="IMAPPort"
                value={formData.IMAPPort}
                onChange={handleInputChange}
                required
                placeholder="Enter your IMAP Port"
                size="small"
              />
            </FormGroup>

            <FormGroup>
              <Label>SMTP Hostname</Label>
              <TextField
                fullWidth
                type="text"
                name="SMTPHostname"
                value={formData.SMTPHostname}
                onChange={handleInputChange}
                required
                placeholder="Enter your SMTP Hostname"
                size="small"
              />
            </FormGroup>

            <FormGroup>
              <Label>SMTP Port</Label>
              <TextField
                fullWidth
                type="number"
                name="SMTPPort"
                value={formData.SMTPPort}
                onChange={handleInputChange}
                required
                placeholder="Enter your SMTP Port"
                size="small"
              />
            </FormGroup>

            <FormGroup>
              <Label>Email</Label>
              <TextField
                fullWidth
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                pattern={validEmail.source}
                placeholder="Enter your email"
                size="small"
              />
            </FormGroup>

            <FormGroup>
              <Label>Password</Label>
              <TextField
                fullWidth
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                required
                minLength={6}
                placeholder="Enter your password"
                size="small"
              />
            </FormGroup>
          </Grid>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <SubmitButton
              type="submit"
              variant="contained"
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={16} color="inherit" />
              ) : (
                "Submit"
              )}
            </SubmitButton>
          </div>
        </FormContainer>
      </Fade>
    </Modal>
  );
}
