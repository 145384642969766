import React, { useState, useEffect } from "react";
import {
  IntegrationCard,
  AuthorizedTitle,
  SubText,
  AuthorizeButton,
} from "./style";
import Check from "../../assets/Check.svg";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { GoogleCalendarUrl, getGoogleTokenAndUserInfo } from "./apiService";
import { toast } from "react-toastify";
import DeleteIcon from "../../assets/delete-grey.svg";

const Email = ({ integration, index, provider, refresh }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isHide, setIsHide] = useState(false);
  const [user, setUser] = useState(null);

  const handleUserLogin = async () => {
    const payload = {
      email: localStorage.getItem("email"),
      password: localStorage.getItem("password"),
    };
    console.log("Payload", payload);
    try {
      const res = await axios.post(
        `https://api.emailflowpro.io/api/user/login`,
        payload
      );
      console.log("User Login Response", res.data);
      setUser(res.data);
    } catch (error) {
      console.log("User Login Error", error);
    }
  };

  useEffect(() => {
    handleUserLogin();
  }, []);

  useEffect(() => {
    if (!provider?.length) {
      return;
    }

    setIsLoading(false);

    const emailProvider = provider[0]?.email_provider;
    if (emailProvider === "oAuthGoogle") {
      setIsAuthorized(true);
    } else if (emailProvider !== "none") {
      setIsHide(true);
    } else {
      setIsAuthorized(false);
      setIsHide(false);
    }
  }, [provider]);

  const SCOPES =
    "https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.labels profile email";

  const handleEmailAuth = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log("Token Response Email : ", tokenResponse);
      try {
        const payload = {
          code: tokenResponse.code,
        };
        const res = await getGoogleTokenAndUserInfo(payload);
        console.log("Google Token and User Info Response", res);
        if (res.isSuccess) {
          const requestBody = {
            email_provider: "oAuthGoogle",
            userId: user.id,
            name: res.data.data.userInfo.name,
            email: res.data.data.userInfo.email,
            password: res.data.data.tokens.access_token,
            imap_hostname: res.data.data.tokens.refresh_token,
          };
          createGmail(requestBody);
        } else {
          setIsAuthorized(false);
          setIsLoading(false);
        }
      } catch (error) {
        console.log("Google Token and User Info Error", error);
      }
    },
    flow: "auth-code",
    redirectUri: GoogleCalendarUrl.REDIRECT_URI,
    scope: SCOPES,
    onNonOAuthError: () => {
      setIsLoading(false);
    },
  });

  const createGmail = async (requestBody) => {
    try {
      const res = await axios.post(
        `https://api.emailflowpro.io/api/provider/createGmail`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      console.log("Create Gmail Response", res.data);
      // if (res.data.emailProvider?.email_provider === "oAuthGoogle") {
      //   setIsAuthorized(true);
      //   setIsLoading(false);
      // }
      refresh();
    } catch (error) {
      console.log("Create Gmail Error", error);
      setIsAuthorized(false);
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const res = await axios.delete(
        `https://api.emailflowpro.io/api/provider/delete/${provider[0]?.id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      console.log("Delete Gmail Response", res.data);
      toast.success(res.message);
      refresh();
      setIsAuthorized(false);
    } catch (error) {
      console.log("Delete Gmail Error", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <IntegrationCard
        key={index}
        style={{
          background: isHide ? "beige" : "#f4f6f6",
          opacity: isHide ? "0.5" : "1",
        }}
      >
        <AuthorizedTitle>
          <span
            style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
          >
            <span>
              <img src={integration.icon} alt="icon" />
            </span>
            {integration.name}
          </span>
          {/* {isAuthorized && <img src={Check} alt="check" />} */}
          {isAuthorized && (
            <img
              src={DeleteIcon}
              alt="delete"
              onClick={!isLoading && handleDelete}
            />
          )}
        </AuthorizedTitle>
        <SubText>{integration.description}</SubText>
        <AuthorizeButton
          onClick={() => {
            handleEmailAuth();
            setIsLoading(true);
          }}
          disabled={isLoading || isAuthorized || isHide}
          isAuthorized={isAuthorized}
        >
          {isLoading ? (
            <CircularProgress size={13} color="inherit" />
          ) : isAuthorized ? (
            "Authorized"
          ) : (
            "Authorize"
          )}
        </AuthorizeButton>
      </IntegrationCard>
    </>
  );
};

export default Email;
