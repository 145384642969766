import React, { useState, useRef, useEffect } from "react";
import { CircularProgress, Tooltip } from "@mui/material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Header, Heading } from "../Summarize/Style";
import { useOutletContext, useNavigate } from "react-router-dom";
import ChatMessage from "./ChatMessage";
import SuggestionMessages from "./SuggestionMessages";
import SkillsSection from "./SkillsSection";
import {
  AvatarSection,
  AssistantName,
  AssistantTitle,
  ChatBox,
  ChatDiv,
  InputContainer,
  Input,
  FileDiv,
  EditImg,
  UploadDiv,
  Container,
  Content,
  NotificationSection,
  TopNavbarSection,
  AddDocument,
  RightNavbarSection,
  SendMessageIcon,
  RightNavItem,
  BelowNavbarSection,
  InnerContainer,
} from "./style";
import { UploadBtn } from "../Summarize/Style";
import BackIcon from "../../assets/back-icon.svg";
import ArrowUpIcon from "../../assets/arrow-up-white.svg";
import PaperClip from "../../assets/paper-clip.svg";
import FileCard from "../Cards/FileCard";
import PdfIcon from "../../assets/pdf-large.svg";
import DocxIcon from "../../assets/docx-large.svg";
import TxtIcon from "../../assets/txt-large.svg";
import s3 from "../Environment/Asconfig";
import EditIcon from "../../assets/edit-avatar.svg";
import { AVATARS } from "../Constants/Constants";
import Delete from "../../assets/delete-grey.svg";
import ComputerIcon from "../../assets/computer-icon.svg";
import AvatarDriveFilePicker from "./AvatarDriveFilePicker";

import AvatarChatBg from "../../assets/avatar_chat_bg.png";
import NotificationWhiteIcon from "../../assets/notification_white_icon.svg";
import AvatarChatHomeIcon from "../../assets/avatar_chat_home_icon.svg";
import AvatarChatCameraIcon from "../../assets/avatar_chat_camera_icon.svg";
import AvatarChatBelowIcon from "../../assets/avatar_chat_below_icon.svg";
import PlusIconWhite from "../../assets/plus_icon_white.svg";
import { TopNavItem } from "./style";

const uploadFileToS3 = async (file) => {
  const selectedUserId = localStorage.getItem("id");
  const folderName = `${selectedUserId}/documents`;
  const key = `${folderName}/${file.name}`;
  const params = {
    Bucket: "aiflowpro-fe-userdata",
    Key: key,
    Body: file,
  };
  const data = await s3.upload(params).promise();
  return data.Location;
};

const formatTimestamp = (date) => {
  const offset = -date.getTimezoneOffset();
  const offsetSign = offset >= 0 ? "+" : "-";
  const offsetHours = String(Math.floor(Math.abs(offset) / 60)).padStart(
    2,
    "0"
  );
  const offsetMinutes = String(Math.abs(offset) % 60).padStart(2, "0");

  return `${date
    .toISOString()
    .slice(0, 19)}${offsetSign}${offsetHours}:${offsetMinutes}`;
};

const getFileTypeFromName = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  if (extension === "pdf") return "pdf";
  if (["doc", "docx"].includes(extension)) return "DOCX";
  if (extension === "txt") return "TXT";
  return "unknown";
};

const formatFileFromUrl = (url) => {
  const fileName = decodeURIComponent(url.split("/").pop());
  return {
    name: fileName,
    type: getFileTypeFromName(fileName),
    url: url,
  };
};

const fetchAvatarData = async () => {
  const userId = localStorage.getItem("id");
  const response = await fetch(
    `https://api.aiflowpro.io/api/auth/get-avatar?user_id=${userId}`
  );
  const data = await response.json();
  return data;
};

const saveChat = async (
  userId,
  query,
  content,
  files,
  userTimestamp,
  avatarTimestamp,
  isGreetingMsg
) => {
  await fetch("https://api.aiflowpro.io/api/auth/avatar-chat", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user_id: userId,
      query,
      content,
      files,
      userTimestamp,
      avatarTimestamp,
      isGreetingMsg,
    }),
  });
};

const checkAssistantOnboarded = async (userId) => {
  const response = await fetch(
    "https://api.aiflowpro.io/api/auth/check-assistant-onboarded",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId }),
    }
  );
  const data = await response.json();
  return data.isAssistantOnboarded;
};

const AvatarChat = () => {
  const { isSidebarOpen } = useOutletContext();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [driveFiles, setDriveFiles] = useState([]);
  const bottomRef = useRef(null);
  const [isAvatarLoading, setIsAvatarLoading] = useState(true);
  const [isChatHistoryLoading, setIsChatHistoryLoading] = useState(true);
  const [avatarData, setAvatarData] = useState(null);
  const [clearingChat, setClearingChat] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [activeSection, setActiveSection] = useState('chat');
  const [isUpdatingOnboarding, setIsUpdatingOnboarding] = useState(false);

  let history = [];
  const fetchSuggestions = async (messageHistory) => {
    const userId = localStorage.getItem("id");
    try {
      // Only proceed with fetching suggestions if assistant is onboarded
      if (!avatarData?.isAssistantOnboarded) {
        setShowSuggestions(false);
        return;
      }

      const payload = {
        user_id: userId,
        language: avatarData?.greetingLanguage,
        ...(messageHistory?.length > 0 && {
          messages: messageHistory.map((msg) => ({
            role: msg.isUser ? "human" : "ai",
            content: msg.text,
          })),
        }),
      };

      const response = await fetch(
        "https://assistant-api.aiflowpro.io/suggestions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();
      const parsedSuggestions = data.suggestions
        ? JSON.parse(data.suggestions)
        : [];
      setSuggestions(parsedSuggestions);
      setShowSuggestions(true);

      // const data = await response.json();
      // setSuggestions(data.suggestions || []);
      // setShowSuggestions(true); // Only show if we get to this point (meaning assistant is onboarded)

      // Add a small delay to ensure DOM updates before scrolling
      setTimeout(() => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setShowSuggestions(false);
    }
  };

  const fetchChatHistory = async () => {
    const userId = localStorage.getItem("id");
    const response = await fetch(
      `https://api.aiflowpro.io/api/auth/avatar-chat?user_id=${userId}`
    );
    const data = await response.json();

    if (data.responseCode === 200) {
      const formattedMessages = data.avatarChats.flatMap((chat) => [
        {
          role: "human",
          content: chat.query,
          files: chat.files.map((fileUrl) => formatFileFromUrl(fileUrl)),
          userTimestamp: chat.userTimestamp,
          isGreetingMsg: chat.isGreetingMsg,
        },
        {
          role: "ai",
          content: chat.content,
          avatarTimestamp: chat.avatarTimestamp,
          isGreetingMsg: chat.isGreetingMsg,
        },
      ]);
      history = formattedMessages;
      const formattedDisplayMessages = formattedMessages.map((msg) => ({
        id:
          msg.role === "human"
            ? msg.content + "-query"
            : msg.content + "-response",
        text: msg.content,
        isUser: msg.role === "human",
        files: msg.role === "human" ? msg.files : [],
        userTimestamp:
          msg.role === "human" ? new Date(parseInt(msg.userTimestamp)) : null,
        avatarTimestamp:
          msg.role === "ai" ? new Date(parseInt(msg.avatarTimestamp)) : null,
        isGreetingMsg: msg.isGreetingMsg,
      }));

      // Add a "Loading..." message for the greeting
      formattedDisplayMessages.push({
        files: [],
        id: "greeting-loading",
        isUser: false,
        text: "Loading...",
        isGreetingMsg: false,
      });
      return formattedDisplayMessages;
    }
    return [];
  };

  const refreshChat = async () => {
    try {
      const userId = localStorage.getItem("id");
      const response = await fetch(
        `https://api.aiflowpro.io/api/auth/avatar-chat?user_id=${userId}`
      );
      const data = await response.json();
  
      if (data.responseCode === 200) {
        const formattedMessages = data.avatarChats.flatMap((chat) => [
          {
            role: "human",
            content: chat.query,
            files: chat.files.map((fileUrl) => formatFileFromUrl(fileUrl)),
            userTimestamp: chat.userTimestamp,
            isGreetingMsg: chat.isGreetingMsg,
          },
          {
            role: "ai",
            content: chat.content,
            avatarTimestamp: chat.avatarTimestamp,
            isGreetingMsg: chat.isGreetingMsg,
          },
        ]);

        // Check if last message contains <WORKFLOW>
        const lastMessage = formattedMessages[formattedMessages.length - 1];
        if (lastMessage && lastMessage.role === "ai" && lastMessage.content.includes("<WORKFLOW>")) {
          setTimeout(() => {
            refreshChat();
          }, 20000);
        }

        history = formattedMessages;
        const formattedDisplayMessages = formattedMessages.map((msg) => ({
          id:
            msg.role === "human"
              ? msg.content + "-query"
              : msg.content + "-response",
          text: msg.content,
          isUser: msg.role === "human",
          files: msg.role === "human" ? msg.files : [],
          userTimestamp:
            msg.role === "human" ? new Date(parseInt(msg.userTimestamp)) : null,
          avatarTimestamp:
            msg.role === "ai" ? new Date(parseInt(msg.avatarTimestamp)) : null,
          isGreetingMsg: msg.isGreetingMsg,
        }));
        setMessages(formattedDisplayMessages);
      }
      
    } catch (error) {
      console.error("Error refreshing chat:", error);
    }
  }
  // In the useEffect, modify the chat initialization

  const initializeChat = async () => {
    try {
      // Avatar Loading
      setIsAvatarLoading(true);
      setIsUpdatingOnboarding(true);
      const avatarResponse = await fetchAvatarData();
      if (avatarResponse.responseCode === 404) {
        navigate("/avatar-selection");
        return;
      }
      if (avatarResponse.responseCode === 200) {
        setAvatarData(avatarResponse.avatar);
        setIsUpdatingOnboarding(false);
        setIsAvatarLoading(false);
      }

        // Chat History Loading
        setIsChatHistoryLoading(true);
        const formattedMessages = await fetchChatHistory();
        await setMessages(formattedMessages);
        setIsChatHistoryLoading(false);

        // Fetch greeting while showing "Loading..." message
        const userId = localStorage.getItem("id");
        const userName = localStorage.getItem("name");

        // Get the last greeting message
        const greetingMessages = formattedMessages.filter(
          (msg) => msg.isGreetingMsg === true
        );
        const lastMessage = greetingMessages[greetingMessages.length - 1];
        console.log("Last Message: ", lastMessage);

        const currentTime = Date.now();
        const fifteenMinutes = 15 * 60 * 1000; // 15 minutes in milliseconds

        // Check if we need a new greeting (no last message or message is older than 15 mins)
        const needsNewGreeting =
          !lastMessage ||
          !lastMessage.avatarTimestamp ||
          currentTime - new Date(lastMessage.avatarTimestamp).getTime() >
            fifteenMinutes;

        if (needsNewGreeting) {
          try {
            // Make API call for new greeting
            const response = await fetch(
              "https://assistant-api.aiflowpro.io/chat",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  user_id: userId,
                  user_name: userName,
                  messages: history,
                  timestamp: formatTimestamp(new Date()),
                  timestampinunix: Date.now(),
                  s3_document_urls: [],
                  is_greeting: true,
                  language: avatarResponse.avatar.greetingLanguage,
                  ...(avatarResponse.avatar.isAssistantOnboarded === false && {
                    is_onboarded: false,
                  }),
                }),
              }
            );

            if (!response.ok) {
              throw new Error(
                `API call failed with status: ${response.status}`
              );
            }

            const newData = await response.json();
            const avatarTimestamp = Date.now();

            // Save the new greeting chat
            await saveChat(
              userId,
              "",
              newData.content,
              [],
              null,
              avatarTimestamp,
              true
            );

            // Update messages state
            setMessages((prevMessages) => {
              const updatedMessages = prevMessages.map((msg) =>
                msg.id === "greeting-loading"
                  ? {
                      files: [],
                      id: `${userId}-greeting`,
                      isUser: false,
                      text: newData.content,
                      userTimestamp: null,
                      avatarTimestamp: avatarTimestamp,
                      isGreetingMsg: true,
                    }
                  : msg
              );

              // Fetch suggestions with updated messages
              fetchSuggestions(updatedMessages);

              return updatedMessages;
            });
          } catch (error) {
            console.error("Error handling greeting:", error);
            // Remove loading message in case of error
            setMessages((prevMessages) =>
              prevMessages.filter((msg) => msg.id !== "greeting-loading")
            );
            fetchSuggestions(formattedMessages);
          }
        } else {
          // No new greeting needed, just remove loading message and fetch suggestions
          setMessages((prevMessages) =>
            prevMessages.filter((msg) => msg.id !== "greeting-loading")
          );
          fetchSuggestions(formattedMessages);
        }
      } catch (error) {
        console.error("Error initializing chat:", error);
        setIsUpdatingOnboarding(false);
        setIsAvatarLoading(false);
        setIsChatHistoryLoading(false);
      } finally {
        setInitialLoadComplete(true);
      }
    };

  useEffect(() => {
    initializeChat();
  }, [navigate]);

  // Separate useEffect for handling scrolling
  useEffect(() => {
    if (bottomRef.current && (messages.length > 0 || initialLoadComplete)) {
      bottomRef.current.scrollIntoView({
        behavior: initialLoadComplete ? "smooth" : "auto",
      });
    }
  }, [messages, initialLoadComplete]);

  // Additional useEffect to handle suggestion changes
  useEffect(() => {
    if (showSuggestions && bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showSuggestions]);

  const handleClearChat = async () => {
    const userId = localStorage.getItem("id");
    const userName = localStorage.getItem("name");
    setClearingChat(true);

    try {
      const response = await fetch(
        "https://api.aiflowpro.io/api/auth/delete-avatar-chat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: userId,
          }),
        }
      );

      const data = await response.json();
      if (data.responseCode === 200) {
        const responseNew = await fetch(
          "https://assistant-api.aiflowpro.io/chat",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_id: userId,
              user_name: userName,
              messages: [],
              timestamp: formatTimestamp(new Date()),
              timestampinunix: Date.now(),
              s3_document_urls: [],
              is_greeting: true,
              language: avatarData.greetingLanguage,
            }),
          }
        );
        const newData = await responseNew.json();
        const avatarTimestamp = Date.now();
        // Save Greeting Chat
        await saveChat(
          userId,
          "",
          newData.content,
          [],
          null,
          avatarTimestamp,
          true
        );
        setShowSuggestions(false);
        setMessages([
          {
            files: [],
            id: "6748965e0d35f8e8816fd171-greeting",
            isUser: false,
            text: newData.content,
            userTimestamp: null,
            avatarTimestamp: avatarTimestamp,
            isGreetingMsg: true,
          },
        ]);
      } else {
        console.error("Failed to clear chat:", data.message);
      }
    } catch (error) {
      console.error("Error clearing chat:", error);
    } finally {
      setClearingChat(false);
    }
  };
  const handleSuggestionClick = (suggestion) => {
    handleSendMessage(suggestion);
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const pop_id = open ? "simple-popover" : undefined;

  const handleDriveFiles = async (fileLinks) => {
    handleClosePopover();
    const apiKey = process.env.REACT_APP_API_KEY;

    const newDriveFiles = fileLinks.map((fileId) => {
      const fileUrl = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media&key=${apiKey}`;
      return {
        name: `Drive File ${fileId}`, // This will be updated when metadata is fetched
        type: "application/octet-stream", // Default type
        isDrive: true,
        driveUrl: fileUrl,
        id: fileId,
      };
    });

    try {
      const updatedFiles = await Promise.all(
        newDriveFiles.map(async (file) => {
          const metadataUrl = `https://www.googleapis.com/drive/v3/files/${file.id}?fields=name,mimeType&key=${apiKey}`;
          const response = await fetch(metadataUrl);
          const metadata = await response.json();

          return {
            ...file,
            name: metadata.name,
            type: metadata.mimeType,
          };
        })
      );

      setUploadedFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
    } catch (error) {
      console.error("Error fetching Drive file metadata:", error);
    }
  };

  const handleFileUpload = (e) => {
    handleClosePopover();
    const files = Array.from(e.target.files).map((file) => ({
      name: file.name,
      type: file.type,
      file: file,
      isDrive: false,
    }));
    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const removeFile = (fileName) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName)
    );
  };

  const sendMessage = async (messageContent, files) => {
    const userId = localStorage.getItem("id");
    const userName = localStorage.getItem("name");

    const userMessage = {
      role: "human",
      content: messageContent,
      files: files,
    };

    // Hide suggestions while processing
    setShowSuggestions(false);
    const userTimestamp = Date.now();

    setMessages((prevMessages) => [
      ...prevMessages,
      {
        id: prevMessages.length + 1,
        text: messageContent,
        isUser: true,
        files: files,
        userTimestamp: userTimestamp,
        avatarTimestamp: null,
      },
    ]);

    setMessages((prevMessages) => [
      ...prevMessages,
      { id: prevMessages.length + 2, text: "Loading...", isUser: false },
    ]);

    const fileUrls = await Promise.all(
      files.map(async (file) => {
        if (file.isDrive) {
          return file.driveUrl;
        } else {
          return uploadFileToS3(file.file);
        }
      })
    );

    // Check onboarding status if avatar is not onboarded
    let isOnboarded = avatarData?.isAssistantOnboarded;
    if (!isOnboarded) {
      isOnboarded = await checkAssistantOnboarded(userId);
      avatarData.isAssistantOnboarded = isOnboarded;
    }

    try {
      const response = await fetch("https://assistant-api.aiflowpro.io/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
          user_name: userName,
          messages: [
            ...messages.map((msg) => ({
              role: msg.isUser ? "human" : "ai",
              content: msg.text,
            })),
            userMessage,
          ],
          timestamp: formatTimestamp(new Date()),
          timestampinunix: Date.now(),
          s3_document_urls: fileUrls,
          ...(isOnboarded === false && {
            is_onboarded: false,
          }),
          language: avatarData.greetingLanguage,
        }),
      });
      const data = await response.json();
      const avatarTimestamp = Date.now();
      setMessages((prevMessages) => {
        const updatedMessages = prevMessages.map((msg) =>
          msg.text === "Loading..."
            ? {
                ...msg,
                text: data.content,
                isUser: false,
                ...(data.url ? { generatedFileUrl: data.url } : {}),
                userTimestamp: null,
                avatarTimestamp: avatarTimestamp,
              }
            : msg
        );

        const lastMessage = updatedMessages[updatedMessages.length - 1];
        if (!lastMessage.isUser && lastMessage.text.includes("<WORKFLOW>")) {
          setTimeout(() => {
            refreshChat();
          }, 20000); // 10 seconds delay
        }
        // Fetch new suggestions after message update
        fetchSuggestions(updatedMessages);

        return updatedMessages;
      });

      await saveChat(
        userId,
        messageContent,
        data.content,
        fileUrls,
        userTimestamp,
        avatarTimestamp,
        false
      );
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.text === "Loading..."
            ? { ...msg, text: "Error sending message", isUser: false }
            : msg
        )
      );
      setShowSuggestions(true); // Show suggestions again in case of error
    }
  };

  const handleSendMessage = async (inputMessage = "") => {
    const message =
      (typeof inputMessage === "string" ? inputMessage.trim() : "") ||
      (typeof inputValue === "string" ? inputValue.trim() : "");

    if (message === "") return;

    const currentUploadedFiles = [...uploadedFiles];
    setUploadedFiles([]);
    setInputValue("");

    await sendMessage(message, currentUploadedFiles);
  };

  const avatarImage = avatarData
    ? AVATARS.find((avatar) => avatar.imageId === avatarData.imageId)?.src
    : null;

  const [isDragging, setIsDragging] = useState(false);
  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    const files = Array.from(droppedFiles).map((file) => ({
      name: file.name,
      type: file.type,
      file: file,
      isDrive: false,
    }));
    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
    setIsDragging(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
    if (!isDragging) setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    const isOutside = !event.currentTarget.contains(event.relatedTarget);
    if (isOutside) {
      setIsDragging(false);
    }
  };

  const handleRunSkill = (skillValue) => {
    setActiveSection('chat'); // Set first nav item active
    handleSendMessage(skillValue);
  };
  
  const renderAvatarSection = () => {
    const avatarImage = avatarData
      ? AVATARS.find((avatar) => avatar.imageId === avatarData.imageId)?.src
      : null;

    return (
      <AvatarSection onClick={() => navigate("/avatar-selection")}>
        <img
          src={avatarImage}
          alt="Avatar"
          style={{
            width: "2.5rem",
            height: "2.5rem",
            borderRadius: "60%",
            background: "#FFF",
          }}
        />
        <AssistantName>
          {avatarData ? avatarData.avatarName : "Michael"}
        </AssistantName>
        {/* <EditImg
          src={EditIcon}
          alt="edit"
          onClick={() => navigate("/avatar-selection")}
        /> */}
        {/* <AssistantTitle>Your Personal Assistant</AssistantTitle> */}
      </AvatarSection>
    );
  };

  const renderChatContent = () => {
    if (isChatHistoryLoading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    // console.log("All Messages : ", messages);
    return (
      <ChatDiv>
        {messages.map((message, index) => (
          <ChatMessage
            key={index}
            text={message.text}
            isUser={message.isUser}
            avatarImage={
              avatarData
                ? AVATARS.find(
                    (avatar) => avatar.imageId === avatarData.imageId
                  )?.src
                : null
            }
            avatarData={avatarData.avatarName}
            files={message.files || []}
            fileUrl={message?.generatedFileUrl}
            userColor="#202226"
            userTextColor="#FFFFFF"
            avatarColor="#EAEAEA"
            avatarTextColor="#202226"
            userTimestamp={message.userTimestamp}
            avatarTimestamp={message.avatarTimestamp}
          />
        ))}
        <div ref={bottomRef} />

      </ChatDiv>
    );
  };

  const renderNotificationSection = () => {
    return (
      <NotificationSection onClick={() => navigate("/notifications")}>
        <img
          width="24px"
          height="24px"
          src={NotificationWhiteIcon}
          alt="notifications"
        />
      </NotificationSection>
    );
  };

  const renderTopNavbarSection = () => {
    const updateAssistantOnboarded = async (userId) => {
      setIsUpdatingOnboarding(true);
      try {

        const response = await fetch(
          "https://api.aiflowpro.io/api/auth/update-assistant-onboarded",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ userId }),
          }
        );
        const data = await response.json();
        setIsUpdatingOnboarding(false);
        if (data.responseCode === 200) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        setIsUpdatingOnboarding(false);
        return false;
      }
    };

    let nav = [
      {
        title: `Chat with ${avatarData?.avatarName}`,
        isActive: activeSection === 'chat',
        onClick: () => {setActiveSection('chat');}, // No specific action defined for this item
        content: avatarData?.avatarName ? `Chat with ${avatarData?.avatarName}` : <CircularProgress size={20} />
      },
      {
        title: "Skills",
        isActive: activeSection === 'skills',
        onClick: () => {setActiveSection('skills');},
      },
      {
        title: "Clear Chat",
        isActive: false,
        onClick: () => {
          if (!clearingChat) {
            handleClearChat();
          }
        },
        content: clearingChat ? <CircularProgress size={20} /> : "Clear Chat",
        style: { backgroundColor: clearingChat ? "#FFF" : undefined },
      },
    ];

    if (avatarData?.isAssistantOnboarded === false) {
      nav.push({
        title: "Skip Onboarding",
        isActive: false,
        onClick: async () => {
          const userId = localStorage.getItem("id");
          const isOnboarded = await updateAssistantOnboarded(userId);
          if (isOnboarded) {
            avatarData.isAssistantOnboarded = true;
          }
        },
        content: isUpdatingOnboarding ? <CircularProgress size={20} /> : "Skip Onboarding"
      });
    }

    return (
      <TopNavbarSection>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {nav.map((item, index) => (
            <TopNavItem
              key={index}
              isActive={item.isActive}
              onClick={() => {
                if (!clearingChat || item.title !== "Clear Chat") {
                  item.onClick();
                }
              }}
              style={item.style}
            >
              {item.content || item.title}
            </TopNavItem>
          ))}
        </div>
        <Tooltip title="Add Document">
          <AddDocument onClick={handleOpenPopover}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 6V12M12 12V18M12 12H18M12 12L6 12"
                stroke="#3E473E"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </AddDocument>
        </Tooltip>
      </TopNavbarSection>
    );
  };

  const renderRightNavbarSection = () => {
    const sideNav = [
      {
        title: "AI Assistant",
        icon: AvatarChatHomeIcon,
        onClick: (event) => {
          event.preventDefault();
        },
        isActive: true,
      },
      {
        title: "Notifications",
        icon: NotificationWhiteIcon,
        onClick: (event) => {
          event.preventDefault();
          navigate("/notifications");
        },
        isActive: false,
      },
      {
        title: "Add Document",
        icon: PlusIconWhite,
        onClick: (event) => {
          event.preventDefault();
          handleOpenPopover(event);
        },
        isActive: false,
      },
      {
        title: "More",
        icon: AvatarChatBelowIcon,
        onClick: (event) => {
          event.preventDefault();
          handleShowBelow();
        },
        isActive: false,
      },
    ];
    const [showBelow, setShowBelow] = useState(false);
    const handleShowBelow = () => {
      setShowBelow(!showBelow);
    };
    const belowNav = [
      {
        title: "Transcriber",
        icon: AvatarChatCameraIcon,
        onClick: (event) => {
          event.preventDefault();
          navigate("/transcriber");
        },
        isActive: false,
      },
    ];
    return (
      <RightNavbarSection>
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          {sideNav.map((item, index) => (
            <Tooltip title={item.title}>
              <RightNavItem
                key={index}
                onClick={(event) => {
                  item.onClick(event);
                }}
                style={{ background: item.isActive ? "#ffffff" : "" }}
              >
                <img
                  style={{
                    width: "20px",
                    height: "20px",
                    transform:
                      item.title === "More" && showBelow
                        ? "rotate(180deg)"
                        : "none",
                    transition: "transform 0.5s ease",
                  }}
                  src={item.icon}
                  alt={item.title}
                />
              </RightNavItem>
            </Tooltip>
          ))}
          {showBelow && (
            <BelowNavbarSection>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "4px" }}
              >
                {belowNav.map((item, index) => (
                  <Tooltip title={item.title}>
                    <RightNavItem
                      key={index}
                      onClick={(event) => {
                        event.preventDefault();
                        item.onClick(event);
                      }}
                      style={{ width: "36px", height: "36px" }}
                    >
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src={item.icon}
                        alt={item.title}
                      />
                    </RightNavItem>
                  </Tooltip>
                ))}
              </div>
            </BelowNavbarSection>
          )}
        </div>
      </RightNavbarSection>
    );
  };

  return (
    <Container
      isOpen={isSidebarOpen}
      style={{
        marginRight: "0rem",
        backgroundImage: `url(${AvatarChatBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "100vh",
        // padding: "1.5rem",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          gap: "1rem",
          padding: "1.5rem 1.5rem 0 1.5rem",
        }}
      >
        {renderNotificationSection()}
        {renderAvatarSection()}
      </div>
      <InnerContainer>
        {renderTopNavbarSection()}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "2.5rem",
            marginTop: "1rem",
          }}
        >
        <Content
          style={{
            height:
              uploadedFiles.length > 0
                ? "calc(100vh - 322px)"
                : "calc(100vh - 252px)",
            // overflowY: "auto"
          }}
        >
        {activeSection === 'chat' ? (
          <ChatBox
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
          >
            {isDragging && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                Drop your files here!
              </div>
            )}
            {renderChatContent()}
            {showSuggestions && (
              <SuggestionMessages
                suggestions={suggestions}
                onSuggestionClick={handleSuggestionClick}
                isPopup={false}
              />
            )}
          </ChatBox>
        ) : (
          <SkillsSection onRunSkill={(skillValue) => {
            setActiveSection('chat');
            handleSendMessage(skillValue);
          }} />
        )}
        </Content>
          {renderRightNavbarSection()}
        </div>
        {activeSection === 'chat' && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "2.5rem",
            marginTop: "1rem",
          }}
        >
          <InputContainer
            style={{
              // borderRadius: uploadedFiles.length > 0 ? "1.25rem" : "6.25rem",
              width: "calc(100% - 135px)",
            }}
          >
            <FileDiv
              style={{ display: uploadedFiles.length > 0 ? "flex" : "none" }}
            >
              {uploadedFiles.map((file, index) => (
                <FileCard
                  key={index}
                  fileName={file?.name}
                  fileType={
                    file?.type?.includes("pdf")
                      ? "pdf"
                      : file?.type?.includes("word")
                      ? "DOCX"
                      : "TXT"
                  }
                  fileIcon={
                    file?.type?.includes("pdf")
                      ? PdfIcon
                      : file?.type?.includes("word")
                      ? DocxIcon
                      : TxtIcon
                  }
                  onRemove={() => removeFile(file.name)}
                  isPopup={false}
                />
              ))}
            </FileDiv>
            <div style={{ display: "flex", width: "100%", gap: "10px" }}>
              <input
                type="file"
                multiple
                accept=".pdf,.doc,.docx,.txt"
                style={{ display: "none" }}
                id="fileInput"
                onChange={handleFileUpload}
              />
              <img
                src={PaperClip}
                alt="attach file"
                onClick={handleOpenPopover}
                style={{ cursor: "pointer" }}
              />
              <Popover
                id={pop_id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    background: "#f1f1f5",
                  }}
                >
                  <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
                    <UploadDiv>
                      <img src={ComputerIcon} alt="computer" /> Upload from
                      computer
                    </UploadDiv>
                  </label>
                  <AvatarDriveFilePicker onFilesSelected={handleDriveFiles} />
                </div>
              </Popover>
              <Input
                type="text"
                placeholder="Type Message"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSendMessage();
                  }
                }}
              />
            </div>
          </InputContainer>
          <div style={{ width: "60px" }}>
            <SendMessageIcon onClick={handleSendMessage}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M23.1829 10.6924L5.59354 2.11215C5.44226 2.03835 5.27615 2 5.10784 2C4.496 2 4 2.496 4 3.10784V3.13981C4 3.28846 4.01823 3.43656 4.05428 3.58078L5.74153 10.3297C5.78761 10.5141 5.94347 10.6503 6.13231 10.6713L13.5483 11.4953C13.8055 11.5238 14 11.7412 14 12C14 12.2588 13.8055 12.4762 13.5483 12.5047L6.13231 13.3287C5.94347 13.3497 5.78761 13.4859 5.74153 13.6703L4.05428 20.4192C4.01823 20.5635 4 20.7115 4 20.8602V20.8922C4 21.504 4.496 22 5.10784 22C5.27615 22 5.44226 21.9616 5.59354 21.8878L23.1829 13.3076C23.6828 13.0638 24 12.5563 24 12C24 11.4437 23.6828 10.9362 23.1829 10.6924Z"
                  fill="white"
                />
              </svg>
            </SendMessageIcon>
          </div>
        </div>
        )}
      </InnerContainer>
    </Container>
  );
};

export default AvatarChat;

/* <Container isOpen={isSidebarOpen} style={{ marginRight: "1.4rem" }}>
  <Header style={{ alignItems: "center", justifyContent: "space-between" }}>
    <div style={{ display: "flex" }} onClick={() => navigate("/")}>
      <img src={BackIcon} alt="back" />
      <Heading>Avatar Chat</Heading>
    </div>
    <UploadBtn
      onClick={handleClearChat}
      disabled={clearingChat}
      style={{ cursor: clearingChat ? "default" : "pointer" }}
    >
      {clearingChat ? (
        <CircularProgress size={20} style={{ marginRight: "0.5rem" }} />
      ) : (
        <img src={Delete} alt="Manage Files" style={{ height: "1.5rem" }} />
      )}
      Clear Chat
    </UploadBtn>
  </Header>
  <Content
    style={{ background: "linear-gradient(180deg, #7EAFFD 0%, #3B71C8 100%)" }}
  >
    {renderAvatarSection()}
    <ChatBox onDrop={handleDrop} onDragOver={handleDragOver}>
      {renderChatContent()}
      {showSuggestions && (
        <SuggestionMessages
          suggestions={suggestions}
          onSuggestionClick={handleSuggestionClick}
          isPopup={false}
        />
      )}
      <InputContainer
        style={{
          borderRadius: uploadedFiles.length > 0 ? "1.25rem" : "6.25rem",
        }}
      >
        <FileDiv
          style={{ display: uploadedFiles.length > 0 ? "flex" : "none" }}
        >
          {uploadedFiles.map((file, index) => (
            <FileCard
              key={index}
              fileName={file?.name}
              fileType={
                file?.type?.includes("pdf")
                  ? "pdf"
                  : file?.type?.includes("word")
                  ? "DOCX"
                  : "TXT"
              }
              fileIcon={
                file?.type?.includes("pdf")
                  ? PdfIcon
                  : file?.type?.includes("word")
                  ? DocxIcon
                  : TxtIcon
              }
              onRemove={() => removeFile(file.name)}
              isPopup={false}
            />
          ))}
        </FileDiv>
        <div style={{ display: "flex", width: "100%", gap: "0.25rem" }}>
          <input
            type="file"
            multiple
            accept=".pdf,.doc,.docx,.txt"
            style={{ display: "none" }}
            id="fileInput"
            onChange={handleFileUpload}
          />
          <img
            src={PaperClip}
            alt="attach file"
            onClick={handleOpenPopover}
            style={{ cursor: "pointer" }}
          />
          <Popover
            id={pop_id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "#f1f1f5",
              }}
            >
              <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
                <UploadDiv>
                  <img src={ComputerIcon} alt="computer" /> Upload from computer
                </UploadDiv>
              </label>
              <AvatarDriveFilePicker onFilesSelected={handleDriveFiles} />
            </div>
          </Popover>
          <Input
            type="text"
            placeholder="Type Message"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSendMessage();
              }
            }}
          />
          <img
            src={ArrowUpIcon}
            alt="send message"
            onClick={handleSendMessage}
            style={{ marginLeft: "auto", cursor: "pointer" }}
          />
        </div>
      </InputContainer>
    </ChatBox>
  </Content>
</Container>; */
