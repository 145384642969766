export const msalConfig = {
  auth: {
    clientId: "33e2d6a4-3f27-44c5-9061-ddca9c7d7d77",
    authority: "https://login.microsoftonline.com/common",
    redirectUri: "http://localhost:3000",
  },
  cache: {
    cacheLocation: "sessionStorage",
  },
};

export const loginRequest = {
  scopes: [
    "User.Read",
    "Files.ReadWrite",
    "Files.ReadWrite.All",
    "offline_access",
  ],
};
