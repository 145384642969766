import React, {useState, useEffect} from 'react'
import Success from '../../assets/PaymentSuccess.svg';
import { useNavigate } from 'react-router-dom';
import StatusDisplay from '../../Components/StatusDisplay/StatusDisplay';
import CircularProgress from '@mui/material/CircularProgress';

const PaymentSuccessful = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handleLoginClick = async () => {
      setIsLoading(true); 
        try {
            const userId = localStorage.getItem('id');
            
            // First API call to update trial status
            const trialResponse = await fetch('https://api.aiflowpro.io/api/auth/update-trial-started', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId }),
            });
            
            const trialData = await trialResponse.json();
            if (trialData.responseCode === 200) {
                localStorage.setItem('isTrialStarted', 'true');
                
                // Second API call to get subscription details
                const subscriptionResponse = await fetch(`https://api.aiflowpro.io/api/auth/subscription-detail?user_id=${userId}`);
                const subscriptionData = await subscriptionResponse.json();
                
                if (subscriptionData.responseCode === 200) {
                    const { stripeCustomerId, packageID, nextInvoice, subsCancelsAt } = subscriptionData.subscriptionDetails;
                    
                    // Store subscription details in localStorage
                    localStorage.setItem('stripeCustomerId', stripeCustomerId);
                    localStorage.setItem('packageID', packageID);
                    localStorage.setItem('nextInvoice', nextInvoice);
                    // localStorage.setItem('subsCancelsAt', subsCancelsAt);
                }
            }
            
            // Original navigation logic
            const isOnboarded = localStorage.getItem('isOnboarded') === 'true';
            navigate(isOnboarded ? '/' : '/introduction');
        } catch (error) {
            console.error('Error during API calls:', error);
            // You might want to handle errors appropriately here
            const isOnboarded = localStorage.getItem('isOnboarded') === 'true';
            navigate(isOnboarded ? '/' : '/introduction');
        } finally {
            setIsLoading(false);
        }
    };
    

    useEffect(() => {
        const isSubscribed = localStorage.getItem('isSubscribed');
        if (isSubscribed !== null) {
          localStorage.setItem('isSubscribed', 'true');
        } else {
          localStorage.setItem('isSubscribed', 'true');
        }
    }, []);

    return (
        <StatusDisplay imgSrc={Success} title={'Payment Successful'} handleClick={handleLoginClick} text={'Thank you for your payment. 😊'} btnText={'Continue to Site'} isLoading={isLoading}/>
    )
}

export default PaymentSuccessful