import React, { useEffect, useState } from "react";
import axios from "axios";
import AvatarDriveFileUploader from "./AvatarDriveFileUploader";
import {
  MessageContainer,
  MessageBubble,
  LoadingText,
  ChatFileDiv,
  UserName,
  TimeStamp,
} from "./style";
import FileCard from "../Cards/FileCard";
import PdfIcon from "../../assets/pdf-large.svg";
import DocxIcon from "../../assets/docx-large.svg";
import TxtIcon from "../../assets/txt-large.svg";
import SnackbarAlert from "../Alerts/SnackbarAlert";
import { Tooltip } from "@mui/material";
import ReactMarkdown from "react-markdown";

const ChatMessage = ({
  text,
  isUser,
  avatarImage,
  avatarData,
  userColor,
  userTextColor,
  avatarColor,
  avatarTextColor,
  files = [],
  fileUrl = "",
  userTimestamp,
  avatarTimestamp,
}) => {
  const [downloadedFile, setDownloadedFile] = useState(null);
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    const fetchFile = async () => {
      if (fileUrl) {
        const fileNameFromUrl = fileUrl.split("drive/")[1]; // Extract the file name from the URL
        setFileName(fileNameFromUrl);

        try {
          const response = await axios.get(fileUrl, { responseType: "blob" }); // Download file as blob
          setDownloadedFile(
            new File([response.data], fileNameFromUrl, {
              type: response.headers["content-type"],
            })
          );
        } catch (error) {
          console.error("Error downloading the file:", error);
        }
      }
    };

    fetchFile();
  }, [fileUrl]);

  const getFileIcon = (fileType) => {
    if (fileType?.includes("pdf")) return PdfIcon;
    if (fileType?.includes("word") || fileType?.includes("docx"))
      return DocxIcon;
    return TxtIcon;
  };

  const getFileType = (fileType) => {
    if (fileType?.includes("pdf")) return "PDF";
    if (fileType?.includes("word") || fileType?.includes("docx")) return "DOCX";
    return "TXT";
  };

  const customComponents = {
    a: ({node, children, href}) => (
      <Tooltip title={href}>
        <button 
          style={{
            background: '#202226',
            padding: '4px 8px',
            borderRadius: '10px',
            border: 'none',
            color: '#FFFFFF',
            cursor: 'pointer'
          }}
          onClick={() => window.open(href, '_self')}
        >
          {children}
        </button>
      </Tooltip>
    )
  };

  if (!text || text.trim() === "") {
    return null;
  }

  return (
    <div>
      {isUser && files.length > 0 && (
        <ChatFileDiv>
          {files.map((file, index) => (
            <FileCard
              key={index}
              fileName={file.name}
              fileType={getFileType(file.type)}
              fileIcon={getFileIcon(file.type)}
              isPopup={false}
              hideCloseButton={true}
            />
          ))}
        </ChatFileDiv>
      )}
      <MessageContainer isUser={isUser}>
        {/* {!isUser && <img src={avatarImage} alt="Avatar" style={{ width: "2.5rem", height: "2.5rem", borderRadius: "60%", background: "#FFF" }} />} */}
        <div
          style={{ display: "flex", flexDirection: "column", maxWidth: "80%" }}
        >
          <div style={{ marginBottom: "8px" }}>
            {!isUser ? (
              <div style={{ display: "flex", gap: "2px" }}>
                <UserName>{avatarData}</UserName>
                {avatarTimestamp && (
                  <TimeStamp>
                    {new Date(avatarTimestamp).toLocaleTimeString()}
                  </TimeStamp>
                )}
              </div>
            ) : (
              <>
                {userTimestamp && (
                  <TimeStamp>
                    {new Date(userTimestamp).toLocaleTimeString()}
                  </TimeStamp>
                )}
              </>
            )}
          </div>
          <MessageBubble
            bgColor={isUser ? userColor : avatarColor}
            textColor={isUser ? userTextColor : avatarTextColor}
            style={{
              borderRadius: isUser
                ? "12px 0px 12px 12px"
                : "12px 12px 12px 0px",
            }}
          >
            {text === "Loading..." ? (
              <LoadingText />
            ) : (
              <ReactMarkdown components={customComponents}>
                {text.replace(/<WORKFLOW>/g, '')}
              </ReactMarkdown>
            )}
          </MessageBubble>
        </div>
      </MessageContainer>
      {!isUser && fileUrl && downloadedFile && (
        <div style={{ marginLeft: "3.25rem" }}>
          <AvatarDriveFileUploader
            file={downloadedFile}
            fileName={fileName}
            onUploadComplete={() => (
              <SnackbarAlert
                message={`${fileName} uploaded successfully!`}
                severity="success"
                autoHideDuration={3000}
              ></SnackbarAlert>
            )}
          />
        </div>
      )}
    </div>
  );
};

export default ChatMessage;