import React, { useState, useEffect } from "react";
import PricingCard from "../../Components/Cards/PricingCard";
import {
  Container,
  ContentDiv,
  Heading,
  MainDiv,
  PlansDiv,
  SubHeading,
  TextDiv,
  LogoutBtn,
  Banner,
} from "./style";
import Logo from "../../assets/Logo.svg";
import LogoutIcon from "../../assets/lougout.svg";
import AlertIcon from "../../assets/alert.svg";
import { useNavigate } from "react-router-dom";

export default function Payment() {
  const [isTrialStarted, setIsTrialStarted] = useState(false);
  const [showHeaderElements, setShowHeaderElements] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedTrialStatus = localStorage.getItem("isTrialStarted");
    const isSubscribed = localStorage.getItem("isSubscribed");
    const trialExpiryDate = localStorage.getItem("trialExpiryDate");
    const trialStatus = storedTrialStatus === null ? true : storedTrialStatus === "true";
    setIsTrialStarted(trialStatus);

    if (isSubscribed === "false") {
      if (!trialExpiryDate || new Date(trialExpiryDate) < new Date()) {
        setShowHeaderElements(true);
      }
    }
  }, []);

  const handleTrialStart = () => {
    setIsTrialStarted(true);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <>
      <Container style={{height: "100vh"}}>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", margin: "0.5rem 1rem"}}>
          <img
            src={Logo}
            alt="logo"
            style={{
              width: "13.0625rem",
              height: "4rem",
            }}
          />
          {showHeaderElements && (
          <LogoutBtn onClick={handleLogout}>
            <img src={LogoutIcon} alt="logout" />
            <p>Logout</p>
          </LogoutBtn>
          )}
          </div>
          {showHeaderElements && (
            <Banner> 
              <img src={AlertIcon} alt="alert" />
              <p>Your free trial has expired. Please choose a plan to continue.</p>
            </Banner>
          )}
        <MainDiv>
          <ContentDiv>
            <TextDiv style={{width: "80%", alignItems: "center"}}>
              <Heading style={{textAlign: "center"}}>Boost Your Efficiency with AI Flow Pro</Heading>
              <SubHeading style={{textAlign: "center"}}>
              At AI Flow Pro, we empower you with cutting-edge AI applications designed to enhance productivity 
              and streamline your workflow. Whether you're an individual professional or part of a larger team, 
              our platform offers AI tools to help you achieve more in less time. Explore our subscription plans 
              and choose the one that best fits your needs.
              </SubHeading>
            </TextDiv>
            <PlansDiv>
              {!isTrialStarted && (
                <PricingCard
                  title="Free Plan"
                  price="0"
                  bgColor="#ffffff"
                  textColor="#0F172A"
                  buttonColor="#0062FF"
                  buttonTextColor="#ffffff"
                  status="/7 days"
                  feature1="Access to AI Flow Pro for 7 days."
                  feature2="Access to all features of AI Flow Pro."
                  feature3="Easily incorporate AI tools into your workflow."
                  onTrialStart={handleTrialStart}
                />
              )}
              <PricingCard
                title="Monthly Plan"
                price="35"
                bgColor="#0062FF"
                textColor="#ffffff"
                buttonColor="#ffffff"
                buttonTextColor="#0062FF"
                status="/month"
                feature1="Seamless Integration: Easily incorporate AI tools into your existing workflow."
                feature2="24/7 Support: Access our dedicated support team anytime you need assistance."
                feature3="Regular Updates: Stay ahead with the latest AI advancements and features."
              />
              <PricingCard
                title="Yearly Plan"
                price="350"
                bgColor="#ffffff"
                textColor="#0F172A"
                buttonColor="#0062FF"
                buttonTextColor="#ffffff"
                status="/year"
                feature1="Exclusive Savings: Save €70 with our yearly subscription."
                feature2="Priority Access: Be the first to access new features and updates.."
                feature3="Enhanced Support: Enjoy premium support with faster response times."
              />
            </PlansDiv>
          </ContentDiv>
        </MainDiv>
      </Container>
    </>
  );
}