import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 1024px;
`;
export const MainDiv = styled.div`
  display: flex;
  justify-content: center;
`;
export const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  justify-content: center;
  max-width: 90rem;
  gap: 2.5rem;
  margin-bottom: 2rem;

  @media (max-width: 1080px) {
    gap: 2rem;
  }
`;

export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.div`
  color: var(--Blue-900, #0f172a);
  font-family: Poppins;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.75rem; /* 125% */
  letter-spacing: -0.125rem;
`;

export const SubHeading = styled.div`
  color: var(--Blue-600, #4b5262);
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.875rem; /* 166.667% */
`;

export const PlansDiv = styled.div`
  display: flex;
  gap: 1.5rem;

  @media (max-width: 670px) {
    flex-direction: column;
  }
`;

//Billing Styling

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 2000px;

  background-color: #fdfdfd;
  min-height: 47.25rem;
  margin-right: 0.5rem;
  margin-left: ${({ isOpen }) => (isOpen ? "18.87rem" : "5.62rem")};
  @media (max-height: 850px) {
    margin-left: 1.87rem;
  }
`;

export const Title = styled.div`
  margin-top: 3.5rem;
  color: #313133;
  font-family: Inter;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.0525rem;
`;
export const Subheader = styled.div`
  color: #171725;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const BillingSection = styled.div`
  flex: 3;
`;

export const SubscriptionCard = styled.div`
  flex: 1;
  width: 15.9475rem;
  padding: 1.87rem 2.5rem;
  border-radius: 0.625rem;
  background: #0062ff;
  box-shadow: 0px 30px 80px 0px rgba(220, 220, 220, 0.2);
`;
export const CardText = styled.div`
  color: #fff;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem; /* 157.143% */
  letter-spacing: 0.00625rem;
`;

export const SubText = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 171.429% */
  letter-spacing: 0.00625rem;
`;

export const CancelButton = styled.div`
  display: inline-flex;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
  border: 1px solid #f1f1f5;

  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  &:hover {
    opacity: 0.8; /* Reduces opacity to 70% on hover */
  }
`;

export const CardInfo = styled.div`
  color: #313133;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const BillingButton = styled.div`
  display: flex;
  padding: 0.5625rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.25rem;
  border: 1px solid #ebebfd;
  cursor: pointer;
  color: #171725;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03rem;
`;

export const RowDiv = styled.div`
  align-items: center;
  display: inline-flex;
  padding: 0.75rem 1.25rem;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 4px 0px rgba(127, 127, 127, 0.15);
  align-self: stretch;
  margin-bottom: 0.87rem;
`;

export const RowText = styled.div`
  color: #171725;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03rem;
`;

export const DownloadButton = styled.div`
  display: flex;
  padding: 0.5625rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.25rem;
  border: 1px solid #ebebfd;
  cursor: pointer;
  &: hover {
    opacity: 0.8;
  }
`;

//Payment-Success
export const PageDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #fff;
`;

export const Icon = styled.img`
  width: 80px; // Adjust size as needed
  height: 80px; // Adjust size as needed
  margin-bottom: 20px;
`;

export const PageTitle = styled.h1`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 10px;
`;

export const Message = styled.p`
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 5px;
`;

export const SubMessage = styled.p`
  font-size: 1rem;
  color: #999;
  margin-bottom: 20px;
`;

export const Button = styled.button`
  background-color: #0046d5;
  color: #fff;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #003bbd;
  }
`;

export const LogoutBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--Blue-900, #0f172a);
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem; /* 200% */
  letter-spacing: -0.01875rem;
  cursor: pointer;
`;

export const Banner = styled.div`
  display: flex;
  // padding: 0.625rem;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: var(--Warning-100, #fef0c7);
  color: var(--Primary-Warning-800, #935f07);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.01875rem;
  margin-bottom: 1rem;
`;
