import React, { useState, useEffect, useRef } from "react";
import { TextField } from "@mui/material";
import { ButtonName } from "./style";

const WorkflowName = ({ workflowSettings, onSave }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const buttonRef = useRef(null);
  const popoverRef = useRef(null);

  useEffect(() => {
    if (workflowSettings.name) {
      setName(workflowSettings.name);
    }
    if (workflowSettings.description) {
      setDescription(workflowSettings.description);
    }
  }, [workflowSettings.name, workflowSettings.description]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setNameError(false);
    setDescriptionError(false);
  };

  const handleAdd = () => {
    if (!name || !description) {
      setNameError(!name);
      setDescriptionError(!description);
      return;
    }
    const workflowSettings = {
      name: name,
      description: description,
    };
    onSave(workflowSettings);
    handleClose();
  };

  return (
    <div style={{ position: "relative" }}>
      <ButtonName ref={buttonRef} onClick={handleOpen}>
        {name ? name : "Add Workflow Name"}
      </ButtonName>
      {isOpen && (
        <div
          ref={popoverRef}
          style={{
            position: "absolute",
            top: "calc(100% + 8px)",
            left: "0",
            backgroundColor: "white",
            borderRadius: "4px",
            boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
            padding: "16px",
            width: "300px",
            zIndex: 1000,
          }}
        >
          <p
            style={{
              marginBottom: "0.5rem",
              marginTop: "0px",
              fontWeight: "500",
            }}
          >
            Title
          </p>
          <TextField
            placeholder="Add a Workflow Name"
            fullWidth
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setNameError(false);
            }}
            error={nameError}
            helperText={nameError ? "Workflow name is required" : ""}
            onMouseDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
          />
          <p style={{ marginBottom: "0.5rem", fontWeight: "500" }}>
            Description
          </p>
          <TextField
            placeholder="Add a Description"
            fullWidth
            multiline
            rows={3}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setDescriptionError(false);
            }}
            error={descriptionError}
            helperText={descriptionError ? "Description is required" : ""}
            onMouseDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
            }}
          >
            <button
              onClick={handleAdd}
              style={{
                padding: "0.5rem 1rem",
                backgroundColor: "#1976d2",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkflowName;
