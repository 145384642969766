import styled from "styled-components";

export const Card = styled.div`
  padding: 2.81rem 1rem 1.94rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 22rem;
  border-radius: 0.625rem;
  border: 0px solid #030303;
  background-color: ${({ bgColor }) => bgColor};
  box-shadow: 0px 30px 80px 0px rgba(220, 220, 220, 0.2);
`;

export const Title = styled.div`
  color: ${({ textColor }) => textColor};
  text-align: center;
  font-family: Poppins;
  font-size: 1.3125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem; /* 152.381% */
  letter-spacing: -0.01875rem;
  margin-bottom: 0.13rem;
`;

export const CardText = styled.div`
  color: ${({ textColor }) => textColor};
  text-align: left;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem; /* 162.5% */
`;

export const PriceText = styled.div`
  color: ${({ textColor }) => textColor};
  text-align: center;
  font-family: Poppins;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.75rem; /* 125% */
  letter-spacing: -0.125rem;
  margin-top: 1.46rem;
`;

export const Features = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1.99rem;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.0625rem;
  opacity: 0.1;
  background: #fff;
  margin-top: 1.21rem;
`;

export const CardButton = styled.div`
  display: flex;
  width: 12.5rem;
  padding: 0.75rem 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.625rem;
  background-color: ${({ buttonColor }) => buttonColor};
  color: ${({ buttonTextColor }) => buttonTextColor};
  text-align: right;

  /* Semi-bold/16px */
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  margin-top: 2.91rem;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

//Source Card Styling
export const SourceItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 0.37rem;
  width: 9.1875rem;
  flex-shrink: 0;
  border-radius: 0.5rem;
  border: 1px solid rgba(235, 235, 253, 0.3);
  background: #f4f6f6;
  height: 3.5rem;
`;

export const SourceTxt = styled.div`
  color: #fff;
  font-family: Poppins;
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 150% */
`;

export const Popup = styled.div`
  position: absolute;
  top: -8.5rem;
  right: -3rem;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 10; /* Ensure the popup is above other content */
  padding: 0.5rem;
  width: 12rem; /* Adjust width as needed */
  border-radius: 0.5rem;
  display: ${(props) =>
    props.isOpen
      ? "block"
      : "none"}; /* Conditionally show/hide based on isOpen prop */
`;

export const PopupItem = styled.div`
  padding: 0.5rem 1rem;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

//FileCard.jsx styling
export const FileBox = styled.div`
  position: relative;
  display: flex;
  width: 30%;
  padding: 0.625rem;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  border-radius: 0.75rem;
  background:  ${(props) => props.isPopup ? "#8B9AB1" : "rgba(0, 0, 0, 0.3)"};
  overflow: hidden;
`;

export const ImageDiv = styled.div`
  display: flex;
  padding:  ${(props) => props.isPopup ? "0.26244rem 0.375rem 0.30006rem 0.375rem" : "0.4375rem 0.625rem 0.5rem 0.625rem"};
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--Components-Input-Component-paddingBlockLG, 0.4375rem);
  background: #fff;
`;

export const FileName = styled.div`
  color: #ececec;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowed text */
  text-overflow: ellipsis; /* Show ellipsis for overflowed text */
  max-width: 100%; /* Ensure it respects the width of FileBox */
`;

export const FileType = styled.div`
  color:  ${(props) => props.isPopup ? "rgba(255, 255, 255, 0.65)" : "#8b9ab1"};
  font-family: "Segoe UI";
  font-size:  ${(props) => props.isPopup ? "0.625rem" : "0.8125rem"};
  font-style: normal;
  font-weight: 400;
  line-height:  ${(props) => props.isPopup ? "1rem" : "1.25rem"};
`;
