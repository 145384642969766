import styled from "styled-components";
import Visa from "../../assets/visaCard-icon.svg";
import DC from "../../assets/DinerClub.svg";
import MasterCard from "../../assets/MasterCard.svg";
import AE from "../../assets/AmericanExpress.svg";
import Discover from "../../assets/Discover.svg";
import BcCard from "../../assets/BCcard.svg";
import Avatar1 from "../../assets/chat-avatar-1.svg";
import Avatar2 from "../../assets/chat-avatar-2.svg";
import Avatar3 from "../../assets/chat-avatar-3.png";
import Avatar4 from "../../assets/chat-avatar-4.svg";
import Avatar5 from "../../assets/chat-avatar-5.svg";
import HubSpotIcon from "../../assets/hubspot.svg";
import DropBoxIcon from "../../assets/dropbox.svg";
import GoogleDriveIcon from "../../assets/google-drive.svg";
import GoogleCalendarIcon from "../../assets/google-calendar.svg";
import MicrosoftCalendarIcon from "../../assets/microscoft-calendar.svg";
import MicrosoftOneDriveIcon from "../../assets/microsoft-onedrive.svg";
import CustomEmailIcon from "../../assets/custom-email.svg";
import GmailIcon from "../../assets/gmail.svg";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";

export const MaskingInfo =
  "Masking encrypts your personal data end to end before sending it to LLM. This will reduce speed of response and might also reduce the quality by 5%.";
export const TimeInfo =
  "For one time task, the time is the time when the task will be performed. For recurring tasks, the time is the time when the task will be triggered for the first time.";

export const CardImgs = {
  visa: Visa,
  amex: AE,
  mastercard: MasterCard,
  dinerclub: DC,
  discover: Discover,
  bccard: BcCard,
};

export const AVATARS = [
  { src: Avatar1, alt: "Avatar 1", imageId: "a1" },
  { src: Avatar2, alt: "Avatar 2", imageId: "a2" },
  { src: Avatar3, alt: "Avatar 3", imageId: "a3" },
  { src: Avatar4, alt: "Avatar 4", imageId: "a4" },
  { src: Avatar5, alt: "Avatar 5", imageId: "a5" },
];

export const getRRuleWeekday = (day) => {
  const weekdayMap = {
    MO: RRule.MO,
    TU: RRule.TU,
    WE: RRule.WE,
    TH: RRule.TH,
    FR: RRule.FR,
    SA: RRule.SA,
    SU: RRule.SU,
  };
  return weekdayMap[day];
};

export const integrations = [
  {
    name: "Gmail",
    icon: GmailIcon,
    description:
      "Securely connect with Gmail using OAuth integration to fetch and send emails",
  },
  {
    name: "Custom Email",
    icon: CustomEmailIcon,
    description:
      "Securely connect any email account using SMTP and IMAP details to send and receive emails seamlessly",
  },
  {
    name: "Google Calendar",
    icon: GoogleCalendarIcon,
    description:
      "Sync your Google Calendar effortlessly to pull events and use them within the app via assistants",
  },
  {
    name: "Google Drive",
    icon: GoogleDriveIcon,
    description:
      "Securely authorize with Google Drive to upload and access your drive files directly within the app",
  },
  {
    name: "Outlook",
    icon: MicrosoftCalendarIcon,
    description:
      "Connect your Microsoft email securely to send and receive emails",
  },
  {
    name: "Microsoft OneDrive",
    icon: MicrosoftOneDriveIcon,
    description:
      "Securely authorize with Microsoft OneDrive to upload and access your OneDrive files directly within the app.",
  },
  {
    name: "Dropbox (Coming Soon)",
    icon: DropBoxIcon,
    description:
      "Securely connect with Dropbox to upload and access your Dropbox files directly within the app",
  },
  {
    name: "HubSpot (Coming Soon)",
    icon: HubSpotIcon,
    description:
      "Seamlessly integrate with HubSpot to access your CRM data and files directly within the app",
  },
];

export const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    border-radius: 1rem;
    border: 1px solid #ebebfd;
    background-color: #fff;

    & fieldset {
      border: none;
    }
  }
`;

export const StyledSelect = styled(Select)`
  width: 15rem;
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  &.MuiOutlinedInput-root {
    border-radius: 1rem;
    background-color: #fff;
  }
`;
export const Grey = "#B9B9B9";
export const White = "#FFFFFF";
export const Black = "#000000";
export const Blue = "#1a7adb";
export const LightGrey = "#F5F5F5";

export const repeatOptions = [
  { value: false, label: "Does not repeat" },
  { value: true, label: "Custom" },
];

export const recurrenceOptions = [
  { value: "One Time", label: "One Time" },
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
];

export const weekDayOptions = [
  { value: "MO", label: "Monday" },
  { value: "TU", label: "Tuesday" },
  { value: "WE", label: "Wednesday" },
  { value: "TH", label: "Thursday" },
  { value: "FR", label: "Friday" },
  { value: "SA", label: "Saturday" },
  { value: "SU", label: "Sunday" },
];

export const occurrenceOptions = [
  { value: "1", label: "First" },
  { value: "-1", label: "Last" },
];

export const commandOptions = [
  {
    value: "Tell me my todos for",
    label: "Tell me my todos for ... (Eg: today)",
  },
  {
    value: "Generate a blog post for me on",
    label: "Generate a blog post for me on ... (Eg: Some topic)",
  },
  {
    value: "Tell me the weather in",
    label: "Tell me the weather in ... (Eg: City name)",
  },
  {
    value: "Tell me my last 5 emails",
    label: "Tell me my last 5 emails ... (Eg: Number can be from 5 - 8)",
  },
  {
    value: "Tell me my last 5 emails from",
    label: "Tell me my last 5 emails from ... (Eg: Email address given)",
  },
  {
    value: "Tell me latest stock updates on",
    label: "Tell me latest stock updates on ... (Eg: Apple)",
  },
  {
    value: "Tell me updates on the following website:",
    label: "Tell me updates on the following website: ...(Eg: Dawn.com)",
  },
];

export const WORKFLOW_ACTIONS = [
  {
    name: "Summarize Document",
    description:
      "Provides a summary of a document stored in S3 or Google Drive given the document URL",
    value: "Provide a summary of the attached document",
    tag: "Document",
    placeholder: "{Document URL}",
  },
  {
    name: "Upload Documents to Database",
    description:
      "Uploads documents to your database to create a knowledge base for you",
    value: "Upload the document to my database",
    tag: "Document",
    placeholder: "{Document URL}",
  },
  {
    name: "Query Documents in Database",
    description:
      "Checks your database and searches information from your documents",
    value: "Check my documents and tell me about",
    tag: "Document",
    placeholder: "{Query Text}",
  },
  {
    name: "Get Documents in Database",
    description:
      "Checks your database and lists all documents from your database",
    value: "Check my database and list all the documents",
    tag: "Document",
    placeholder: "{Database ID}",
  },
  {
    name: "Chat with the Document",
    description:
      "Enables interactive querying to extract information from a specific document",
    value: "From the attached document, can you please tell me",
    tag: "Document",
    placeholder: "{Document URL}",
  },
  {
    name: "Add To-Do",
    description: "Creates a new to-do item for the user",
    value: "Add a to-do",
    tag: "To-Do",
    placeholder: "{To-Do Description}",
  },
  {
    name: "Get To-Do",
    description: "Retrieves your to-do list",
    value: "Get my to-do list",
    tag: "To-Do",
    placeholder: "{User ID}",
  },
  {
    name: "Complete To-Do",
    description: "Marks a specific to-do item as complete",
    value: "Mark a todo as completed",
    tag: "To-Do",
    placeholder: "{To-Do ID}",
  },
  {
    name: "Delete To-Do",
    description: "Deletes a specified to-do item from the list",
    value: "Remove a todo from my to-do list",
    tag: "Task",
    placeholder: "{To-Do ID}",
  },
  {
    name: "Get Meeting Details",
    description: "Fetched meeting date or topic details of meeting",
    value: "Show me the meeting topic and details from my meetings list",
    tag: "Meeting",
    placeholder: "{Meeting ID}",
  },
  {
    name: "Get Time",
    description:
      "Provides the current time based on your location or preference",
    value: "What time is it now?",
    tag: "Time",
    placeholder: "{Location}",
  },
  {
    name: "Blog Post Generation",
    description: "Generates a blog post based on a given topic or prompt",
    value: "Write a blog post on the following topic",
    tag: "Content",
    placeholder: "{Topic}",
  },
  {
    name: "Check Content by Person",
    description:
      "Analyzes content to align it with a specific persona’s voice or style",
    value:
      "Analyze this content for a professional audience and provide a score",
    tag: "Content",
    placeholder: "{Content URL}",
  },
  {
    name: "Split Content by Platform",
    description:
      "Creates engaging and platform-specific social media posts based on the given content",
    value: "Split this content for LinkedIn, Tiktok & Instagram",
    tag: "Content",
    placeholder: "{Content URL}",
  },
  {
    name: "SEO Analysis Report",
    description:
      "Provides an SEO analysis for a webpage or content, including keyword optimization",
    value: "Analyze the SEO of this blog post",
    tag: "Content",
    placeholder: "{Webpage URL}",
  },
  {
    name: "Generate Webpage Content",
    description: "Creates content for a specific webpage based on user input",
    value: "Generate content for the following webpage",
    tag: "Content",
    placeholder: "{Webpage URL}",
  },
  {
    name: "Create Purpose of Existence Document",
    description:
      "Drafts a document outlining the purpose or mission of an entity",
    value: "Create a purpose of existence document for me",
    tag: "Document",
    placeholder: "{Entity Name}",
  },
  {
    name: "Get Weather Details",
    description: "Provides current weather details for a specified location",
    value: "What’s the weather like in New York?",
    tag: "General",
    placeholder: "{Location}",
  },
  {
    name: "Save to Drive",
    description:
      "This tool lets you save the text in your preferred file format: pdf, docx, or txt",
    value: "Save this document to my Google Drive",
    tag: "Document",
    placeholder: "{File Format}",
  },
  {
    name: "Get Skills",
    description: "Lists available skills that the user has created",
    value: "What skills are available?",
    tag: "Skill",
    placeholder: "{User ID}",
  },
  {
    name: "Run Skill",
    description: "Executes a specific skill as requested",
    value: "Run the ____ skill",
    tag: "Skill",
    placeholder: "{Skill Name}",
  },
  {
    name: "Send Emails",
    description: "Sends an email with the specified content",
    value: "Send an email to ____ about the ____",
    tag: "Email",
    placeholder: "{Recipient Email} {Subject}",
  },
  {
    name: "Translate Text",
    description: "Translates a given text into a specified language",
    value: "Translate this text into ____",
    tag: "General",
    placeholder: "{Text} {Language}",
  },
  {
    name: "Query Website",
    description: "Extracts or searches information from a specified website",
    value: "Find the contact details on this website ____",
    tag: "General",
    placeholder: "{Website URL}",
  },
  {
    name: "Create Quiz",
    description:
      "Generates a quiz based on a topic or content provided by the user",
    value: "Create a quiz on ____",
    tag: "Content",
    placeholder: "{Topic}",
  },
  {
    name: "Explain Anything",
    description: "Provides detailed explanations on a topic or concept",
    value: "Explain ____ in simple terms",
    tag: "General",
    placeholder: "{Topic}",
  },
  {
    name: "Research Papers Fetcher",
    description:
      "Finds and retrieves academic research papers on a specified topic",
    value: "Fetch research papers on topic ____ ",
    tag: "General",
    placeholder: "{Topic}",
  },
  {
    name: "Stock Market News",
    description: "Delivers the latest news and updates on the stock market",
    value: "What’s the latest stock price of ____ ",
    tag: "General",
    placeholder: "{Stock Symbol}",
  },
  {
    name: "VAT Validation and Rate Checker",
    description: "Validates VAT numbers and calculates applicable VAT rates",
    value:
      "Validate this VAT number and calculate the rate for Germany.{Vat_Number} {Amount_for_which_user_wants_to_calculate_rate} ",
    tag: "General",
    placeholder: "{VAT Number} {Amount}",
  },
  {
    name: "Save Assistant Task",
    description: "Saves specific tasks for the assistant to remember",
    value: "Save a task to remind me to ____",
    tag: "Task",
    placeholder: "{Task Description}",
  },
  {
    name: "Show Assistant Tasks",
    description: "Displays a list of saved assistant tasks",
    value: "What tasks have I saved with you",
    tag: "Task",
    placeholder: "{User ID}",
  },
  {
    name: "YouTube Comments Summarizer",
    description:
      "Summarizes the comments of a given YouTube video based on the provided URL",
    value: "Summarize the comments on this YouTube video: {YouTube video URL}",
    tag: "General",
    placeholder: "{YouTube Video URL}",
  },
];

export const filterTags = [
  { value: "", label: "All" },
  { value: "workflow", label: "Workflow" },
  { value: "skill", label: "Skill" },
  { value: "document", label: "Document" },
  { value: "email", label: "Email" },
  { value: "To-Do", label: "To-Do" },
  { value: "content", label: "Content" },
  { value: "meeting", label: "Meeting" },
  { value: "time", label: "Time" },
  { value: "task", label: "Task" },
  { value: "general", label: "General" },
];

export const tagColors = {
  workflow: { bg: "rgba(26, 116, 219, 0.2)", text: "#1A74DB" },
  skill: { bg: "rgba(75, 192, 192, 0.2)", text: "#2E9E9E" },
  document: { bg: "rgba(255, 159, 64, 0.2)", text: "#FF7B00" },
  email: { bg: "rgba(153, 102, 255, 0.2)", text: "#7B4DFF" },
  "to-do": { bg: "rgba(255, 99, 132, 0.2)", text: "#FF4D6B" },
  content: { bg: "rgba(54, 162, 235, 0.2)", text: "#2185D0" },
  meeting: { bg: "rgba(255, 206, 86, 0.2)", text: "#E6B800" },
  time: { bg: "rgba(75, 192, 192, 0.2)", text: "#4CAF50" },
  task: { bg: "rgba(255, 99, 132, 0.2)", text: "#E91E63" },
  general: { bg: "rgba(128, 128, 128, 0.2)", text: "#666666" },
};
