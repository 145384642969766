import React, { useState, useEffect, useMemo } from "react";
import {
  IntegrationCard,
  AuthorizedTitle,
  SubText,
  AuthorizeButton,
} from "./style";
import { CircularProgress } from "@mui/material";
import DeleteIcon from "../../assets/delete-grey.svg";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "./authConfig";

const MicrosoftDrive = ({ integration, index }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const { instance, accounts } = useMsal();

  const handleAuthorize = async () => {
    setIsLoading(true);
    console.log("instance", instance);
    try {
      const loginResponse = await instance.loginPopup(loginRequest);
      console.log("Login response:", loginResponse);
      setIsAuthorized(true);
    } catch (error) {
      console.error("Error authorizing:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = () => {
    console.log("Delete");
  };

  return (
    <IntegrationCard key={index}>
      <AuthorizedTitle>
        <span style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <span>
            <img src={integration.icon} alt="icon" />
          </span>
          {integration.name}
        </span>
        {isAuthorized && (
          <img src={DeleteIcon} alt="delete" onClick={handleDelete} />
        )}
      </AuthorizedTitle>
      <SubText>{integration.description}</SubText>
      <AuthorizeButton
        onClick={handleAuthorize}
        disabled={isLoading || isAuthorized}
        isAuthorized={isAuthorized}
      >
        {isLoading ? (
          <CircularProgress size={13} color="inherit" />
        ) : isAuthorized ? (
          "Authorized"
        ) : (
          "Authorize"
        )}
      </AuthorizeButton>
    </IntegrationCard>
  );
};

export default MicrosoftDrive;
