import React, { useEffect, useState, useRef } from "react";
import ZapIcon from "../../assets/workflows/zap-icon.svg";
import ConditionIcon from "../../assets/workflows/condition-icon.svg";
import TriggerIcon from "../../assets/workflows/trigger-icon.svg";
import EndIcon from "../../assets/workflows/end-white-icon.svg";
import AddIcon from "../../assets/workflows/add-icon.svg";
import {
  NodeContainer,
  AddNodeContainer,
  HorizontalLine,
  VerticalLine,
  YesNoContainer,
  YesNoText,
} from "./style";
import { v4 as uuidv4 } from "uuid";
import TriggerPopovers from "./triggerPopovers";
import Popovers from "./Popovers";

const Node = ({
  node,
  treeData,
  setTreeData,
  onDelete,
  newNodeId,
  setNewNodeId,
  allTools,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElTrigger, setAnchorElTrigger] = useState(null);
  const nodeContainerRef = useRef({});

  const calculatePathLine = () => {
    const elements = document.getElementsByClassName("path-line");
    for (let i = elements.length - 1; i >= 0; i--) {
      const element = elements[i];

      const parentNode = element.parentElement;
      parentNode.style.height = parentNode.parentElement.offsetHeight + "px";
      const nextSibling = parentNode.nextElementSibling;
      // const horizontalLine = parentNode.previousElementSibling;
      // const nodeContainer = horizontalLine.previousElementSibling;
      const childNodes = nextSibling.children;
      const child1Height = childNodes[0].offsetHeight;
      const child2Height = childNodes[1].offsetHeight;
      element.style.height = child1Height / 2 + child2Height / 2 + 100 + "px";
      element.style.marginTop = child1Height / 2 + "px";
      // horizontalLine.style.marginTop =
      //   child1Height / 2 +
      //   (child1Height / 2 + child2Height / 2 + 100) / 2 +
      //   "px";
      // nodeContainer.style.marginTop =
      //   child1Height / 2 +
      //   (child1Height / 2 + child2Height / 2 + 100) / 2 -
      //   nodeContainer.offsetHeight / 2 +
      //   "px";
      // if (nextSibling) {
      //   console.log("Next sibling:", nextSibling);
      // } else {
      //   console.log("No next sibling found for:", element);
      // }
    }
  };

  useEffect(() => {
    calculatePathLine();
  }, [treeData, node]);

  const openNodeConfig = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (type) => {
    if (type === "Delete") {
      setTreeData((prevTree) => {
        const removeNode = (nodes, nodeId) => {
          return nodes
            .map((node) => {
              if (node.children) {
                node.children = removeNode(node.children, nodeId);
              }
              return node;
            })
            .filter((node) => node.id !== nodeId);
        };
        return removeNode(prevTree, node.id);
      });
    }
    onDelete(type);
    setAnchorEl(null);
  };

  const generateNode = (name, img) => ({
    id: uuidv4(),
    name: name || "Action",
    img: img,
    formData: {},
    children: [],
  });

  const handleSave = (formData) => {
    setTreeData((prevTree) => {
      const updateNode = (nodes) =>
        nodes.map((n) => {
          if (n.id === node.id) {
            return { ...n, name: formData.name, formData: formData };
          }
          if (n.children) {
            return { ...n, children: updateNode(n.children) };
          }
          return n;
        });

      return updateNode(prevTree);
    });

    setAnchorEl(null);
  };

  const handleOpenTrigger = (event) => {
    setAnchorElTrigger(event.currentTarget);
  };

  const handleChooseTrigger = (selectedTrigger) => {
    setAnchorElTrigger(null);

    let newNode;
    if (selectedTrigger.name === "Action") {
      newNode = generateNode(selectedTrigger.name, "ZapIcon");
    } else if (selectedTrigger.name === "Condition") {
      newNode = {
        id: uuidv4(),
        name: selectedTrigger.name,
        img: "ConditionIcon",
        formData: {},
        children: [
          generateNode("Action", "ZapIcon"),
          generateNode("Action", "ZapIcon"),
        ],
      };
    } else {
      newNode = generateNode(selectedTrigger.name, "EndIcon");
    }
    node.children.push(newNode);
    setNewNodeId(newNode.id);
  };

  useEffect(() => {
    if (newNodeId === node.id) {
      const currentRef = nodeContainerRef.current[node.id];
      if (currentRef) {
        currentRef.click();
        setNewNodeId(null);
      }
    }
  }, [node]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {node.children?.length === 1 && (
        <>
          <NodeContainer
            ref={(ref) => (nodeContainerRef.current[node.id] = ref)}
            onClick={(event) => openNodeConfig(event)}
          >
            <img
              src={
                node.img === "ConditionIcon"
                  ? ConditionIcon
                  : node.img === "TriggerIcon"
                  ? TriggerIcon
                  : ZapIcon
              }
              alt={node.name}
            />
            {node.name}
          </NodeContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <HorizontalLine />
            <Node
              key={node.children[0].id}
              node={node.children[0]}
              treeData={treeData}
              setTreeData={setTreeData}
              onDelete={onDelete}
              newNodeId={newNodeId}
              setNewNodeId={setNewNodeId}
              allTools={allTools}
            />
          </div>
        </>
      )}
      {node.children?.length === 2 && (
        <>
          <NodeContainer
            ref={(ref) => (nodeContainerRef.current[node.id] = ref)}
            onClick={(event) => openNodeConfig(event)}
          >
            <img
              src={node.img === "ConditionIcon" ? ConditionIcon : ZapIcon}
              alt={node.name}
            />
            {node.name}
          </NodeContainer>
          <HorizontalLine />
          <div>
            <div className="path-line" style={{ display: "flex" }}>
              <VerticalLine />
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <HorizontalLine />
                <YesNoContainer>
                  <YesNoText>Yes</YesNoText>
                  <YesNoText>No</YesNoText>
                </YesNoContainer>
                <div style={{ marginBottom: "-3px" }}>
                  <HorizontalLine />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "100px" }}
          >
            <Node
              key={node.children[0].id}
              node={node.children[0]}
              treeData={treeData}
              setTreeData={setTreeData}
              onDelete={onDelete}
              newNodeId={newNodeId}
              setNewNodeId={setNewNodeId}
              allTools={allTools}
            />
            <Node
              key={node.children[1].id}
              node={node.children[1]}
              treeData={treeData}
              setTreeData={setTreeData}
              onDelete={onDelete}
              newNodeId={newNodeId}
              setNewNodeId={setNewNodeId}
              allTools={allTools}
            />
          </div>
        </>
      )}
      {!node.children?.length && (
        <>
          {node.name === "End" ? (
            <AddNodeContainer onClick={(event) => openNodeConfig(event)}>
              <img src={EndIcon} alt="End" />
            </AddNodeContainer>
          ) : (
            <>
              <NodeContainer
                ref={(ref) => (nodeContainerRef.current[node.id] = ref)}
                onClick={(event) => openNodeConfig(event)}
              >
                <img
                  src={
                    node.img === "ConditionIcon"
                      ? ConditionIcon
                      : node.img === "TriggerIcon"
                      ? TriggerIcon
                      : ZapIcon
                  }
                  alt={node.name}
                />
                {node.name}
              </NodeContainer>
              <HorizontalLine />
              <AddNodeContainer onClick={(event) => handleOpenTrigger(event)}>
                <img src={AddIcon} alt="Add Node" />
              </AddNodeContainer>
            </>
          )}
        </>
      )}
      <TriggerPopovers
        anchorElTrigger={anchorElTrigger}
        onChooseTrigger={handleChooseTrigger}
        onCloseTrigger={() => setAnchorElTrigger(null)}
      />
      <Popovers
        anchorEl={anchorEl}
        treeData={treeData}
        tools={allTools}
        node={node}
        onSave={handleSave}
        onClose={handleClose}
      />
    </div>
  );
};

export default Node;
